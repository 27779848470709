/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import { Storage } from '@ionic/storage';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';
import { GlobalDataService } from './global-data.service';

@Injectable()
export class EmailServices {

  userLogged: any;
  headers: HttpHeaders;
  url: string;

  constructor (
                public http: HttpClient,
                private gds: GlobalDataService,
                public localStorage: Storage )
    {
      this.headers = new HttpHeaders();
      this.headers = this.headers.set('Content-Type', 'application/json');
      //this.headers = this.headers.set('X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey);
      this.gds.getAgentId().subscribe(
        (data: any) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Agent Id as data is undefined');
          }
          else {
            console.log('Setting X-Exme-Agent-Id value');
            console.log(data);
            this.headers = this.headerSet(this.headers,'X-Exme-Agent-Id', data.toString());
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

      this.gds.getSessionToken().subscribe(
        (data: string) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Session Token as data is undefined');
          }
          else {
            console.log('X-ExME-Session-Token');
            this.headers = this.headerSet(this.headers,'X-ExME-Session-Token', data);
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );
    this.userLogged = localStorage.get('userSession');
    }

    sendEmail(emailBody: any) {

      return this.localMakeRequest('sendEmail',emailBody,'ExMEPublic','sendEmail',null,false);

/*      const body = emailBody;
      let url = environment.apiUrl + 'api/v2/exmeemail';
      console.log('sending mail. Body:');
      console.log(emailBody);

      const returnHttp = this.http.post(url, body, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
      console.log(returnHttp);
      return returnHttp;
 */
    }


    localMakeRequest(callFunction: string, body: any , service: string , operation: string, agent_id: string , deleteSession: boolean) {
      console.log('Post Body called from: ' + callFunction);
      console.log(JSON.stringify(body));

      const url = environment.apiUrl+ 'api/v2/' + service;
      console.log('Posting to URL: ' + url);
      // AMPM 20230629
      //let localHeader =  this.headers.append('X-Exme-Operation', operation).set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      let localHeader = this.headerSet(this.headers,'X-Exme-Operation', operation);
      console.log(localHeader);
      localHeader = this.headerSet(localHeader,'X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      console.log(localHeader);
      if ( deleteSession ) {
        //localHeader = localHeader.delete('X-ExME-Session-Token');
        localHeader = this.headerSet(localHeader,'X-ExME-Session-Token',null);
        console.log(localHeader);
      }
      if ( agent_id != null && localHeader.get('X-Exme-Agent-Id') != agent_id ) {
        //localHeader = localHeader.delete('X-Exme-Agent-Id').set('X-Exme-Agent-Id',agent_id);
        localHeader = this.headerSet(localHeader,'X-Exme-Agent-Id',agent_id);
        console.log(localHeader);

      }
      console.log('Headers for call from ' + callFunction + ' are:');
      console.log(localHeader);
      return this.http.post(url, body, {headers: localHeader}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );

    }

    private headerSet(sourceHeader: HttpHeaders, changeKey: string, changeValue: any){
      let retHeader=new HttpHeaders();
      sourceHeader.keys().forEach( (key) => {
        if ( key !== changeKey){
          retHeader=retHeader.append(key,sourceHeader.get(key));
        } else {
        }
      });
      if ( changeValue !== undefined && changeValue !== null && changeValue !== '') {
        retHeader=retHeader.append(changeKey,changeValue);
//        console.log('Appending Key');
      }
//      console.log('Returning:');
//      console.log(retHeader);
      return retHeader;
    }


    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
      } else {
        console.error(error) ;
  //        `Backend returned code ${error.status}, ` +
  //        `error message was: ${error.error.error.message}`);
      }
      return throwError('Something bad happened; please try again later.');
    }

    private extractData(res: Response) {
      const body = res;
      return body || { };
    }


}
