import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

  constructor() {}

  handleThemeChange = (mediaQuery) =>
    document.body.classList.toggle('dark', mediaQuery.matches);

  chooseTheme(mode) {
    if (mode === 'dark') {
      this.prefersDark.removeEventListener('change', this.handleThemeChange);
      localStorage.removeItem('theme');
      localStorage.setItem('theme', 'dark');
      document.body.classList.toggle('dark', true);
    } else if (mode === 'light') {
      this.prefersDark.removeEventListener('change', this.handleThemeChange);
      localStorage.removeItem('theme');
      localStorage.setItem('theme', 'light');
      document.body.classList.toggle('dark', false);
    } else if (mode === 'auto' || mode === null) {
      localStorage.removeItem('theme');
      localStorage.setItem('theme', 'auto');

      document.body.classList.toggle('dark', this.prefersDark.matches);

      // Listen for changes to the prefers-color-scheme media query
      this.prefersDark.addEventListener('change', this.handleThemeChange);
    }
  }
}
