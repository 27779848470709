import {Injectable} from "@angular/core";
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class OneSignalApi {

  headers: HttpHeaders;
  url: string;
  appId: string;


  constructor (
                public http: HttpClient,
                public localStorage: Storage )
    {

    }

    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
      } else {
        console.error(
          `Backend returned code ${error.status}, ` +
          `error message was: ${error.error.error.message}`);
      }
      return throwError('Something bad happened; please try again later.');
    }

    private extractData(res: Response) {
      const body = res;
      return body || { };
    }


    sendPushNotification(agent_id: string, title: any, subtitle: any, message_body: any) {

      this.headers = new HttpHeaders();
      this.headers = this.headers.set("Content-Type", "application/json; charset=utf-8");
      this.headers = this.headers.set("Authorization", "Basic MjQ5MTFhMDItNjFkYS00MDNiLTk3ZGItMzg3ZGQ5MjI0NDNk");

      this.url = "https://onesignal.com/api/v1/notifications"
      this.appId = "aea12ae3-de12-44cb-9f0e-119ba39fcb82";

      var message = {
        app_id: this.appId,
        headings: title,
        subtitle: subtitle,
        contents: message_body,
        filters: [
      	  	{"field": "tag", "key": "agentId", "relation": "=", "value": agent_id}
      	]
      };

      return this.http.post(this.url, message, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
//        .map((response: Response) => response.json())

    }



  }
