import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { Platform, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Market } from '@ionic-native/market/ngx';

import { common } from '../../environments/environment';
import { EncriptionService } from './encription.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  private agentId = new BehaviorSubject<string|null>('');
  private agentLanguage = new BehaviorSubject<string|null>('');
  private agentEmail = new BehaviorSubject('');
  private userAvatar = new BehaviorSubject('');
  private userTradeRating = new BehaviorSubject<number|null>(null);
  private numEvals = new BehaviorSubject<number|null>(null);
  private firstName = new Subject<string>();
  private lastName = new Subject<string>();
  private evaluations = new Subject<any>();
  private helpAccessMethod = new BehaviorSubject<boolean>(false);
  private deviceCountry = new BehaviorSubject<string>('');
  private userCountry = new Subject<string>();
  private deviceLanguage = new BehaviorSubject('en');
  private agentScore = new Subject<any>();
  private deviceId = new Subject<any>();
  public devId = '';
  private conversationId = new BehaviorSubject<any|null>(null);

  private agent = new BehaviorSubject<any|null>(null);
  public userSession = new BehaviorSubject([]);
  public sessionToken = new BehaviorSubject('');
  public keyPass = new BehaviorSubject([]);
  public userLoginType = new BehaviorSubject('');

  public activeCollection = new BehaviorSubject<any|null>(null);
  public activeClub = new BehaviorSubject<any|null>(null);
  public tradeOption = new BehaviorSubject<any|null>(null);
  public tradeRequest = new BehaviorSubject<any|null>(null);
  public resetConversationId = new BehaviorSubject<any|null>(null);
  public badgeRefreshValue = new Subject<number>();
  public lastProposalDate = new Subject<number>();
  public lastMarketEvaluation: number;
  private recalculateTradeOptions = new BehaviorSubject<boolean|null>(null);
  private brandNewAgent = new BehaviorSubject<string|null>('');
  public badges = new BehaviorSubject<Array<number>>([0, 0, 0, 0, 0, 0, 0]);

  /*Para testes*/
  private fooSubject = new Subject<any>();

  constructor(
//    private agentsService: AgentsService,
    private localStorage: Storage,
    private router: Router,
    private market: Market,
    private platform: Platform,
    private alertCtrl: AlertController,
    private translate: TranslateService,
//    private langProvider: LanguageProvider,
    private crypto: EncriptionService
  ) {
    this.init();
    this.badges.next([0, 0, 0, 0, 0, 0, 0]);
    this.getAgentFromStorage();
    this.getSessionTokenFromStorage();

  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    this.localStorage = await this.localStorage.create();
  }

  // Badges --------------------
  publishBadges(data: any) {
    this.badges.next(data);
  }
  resetBadges() {
    this.badges.next([0, 0, 0, 0, 0, 0, 0]);
  }
  getBadges(): BehaviorSubject<any> {
    return this.badges;
  }

  publishLastProposalDate(data: any) {
    this.lastProposalDate.next(data);
  }
  resetLastProposalDate() {
    this.lastProposalDate.next();
  }
  getLastProposalDate(): Subject<number> {
    return this.lastProposalDate;
  }

  /*Para testes*/
  publishSomeData(data: any) {
    this.fooSubject.next(data);
  }
  /*Para testes*/
  getObservable(): Subject<any> {
    return this.fooSubject;
  }

  evtRefreshBadgesValue(agentId) {
    this.publishSomeData({refreshBadgesMenu: 1, refreshAgentId : agentId});
  }

  publishSessionToken(data: string) {
    this.localStorage.set('sessionToken', data);
    this.sessionToken.next(data);
  }
  getSessionToken(): BehaviorSubject<any> {
    return this.sessionToken;
  }

  getSessionTokenFromStorage() {
    this.localStorage.get('sessionToken').then( (res) => { if (res !== undefined && res !== null ) { this.sessionToken.next(res);}});
    
  }

  publishUserSession(data: any) {
    this.localStorage.set('userSession', data);
    this.userSession.next(data);
    this.publishSessionToken((data.session_token===undefined?data.session_id:data.session_token));
    this.localStorage.set('userLoginType', common.authUser);
    this.userLoginType.next(common.authUser);
  }
  getUserSession(): BehaviorSubject<any> {
    return this.userSession;
  }

  publishUserLoginType(data: any) {
    this.localStorage.set('userLoginType', data);
    this.userLoginType.next(data);
  }
  getUserLoginType(): BehaviorSubject<any> {
    return this.userLoginType;
  }
  getUserLoginTypeFromStorage(): Promise<any> {
    return this.localStorage.get('userLoginType');
  }

  publishUserKeyPass(data: any) {
    if (data.email === '' || data.email === null || data.email ===undefined) { return; };
    console.log('Received Login data:' + JSON.stringify(data) );
    data.password = this.crypto.encrypt(data.password, common.encryptKey);
    console.log('Storing Login data:' + JSON.stringify(data) );
    this.localStorage.set('keyPass', data);
    this.keyPass.next(data);
  }
  getUserKeyPass(): BehaviorSubject<any> {
    return this.keyPass;
  }
  getUserKeyPassFromStorage(): Promise<any> {
    console.log('returning data');
    console.log(this.localStorage.get('keyPass'));
    return this.localStorage.get('keyPass');
  }

  publishResetConversationId(data: any) {
    this.resetConversationId.next(data);
  }
  getResetConversationId(): Subject<any> {
    return this.resetConversationId;
  }

  publishTradeOption(data: any) {
    this.tradeOption.next(data);
  }
  getTradeOption(): BehaviorSubject<any|null> {
    return this.tradeOption;
  }

  publishTradeRequest(data: any) {
    this.tradeRequest.next(data);
  }
  getTradeRequest(): BehaviorSubject<any|null> {
    return this.tradeRequest;
  }

  publishBadgeValue(data: number) {
    this.badgeRefreshValue.next(data);
  }
  getBadgeValue(): Subject<any> {
    return this.badgeRefreshValue;
  }
  publishActiveCollection(data: any) {
    console.log("GlobalDataService--> Publishing Active Collection")
    this.localStorage.set("activeCollection",data);
    this.activeCollection.next(data);
  }
  getActiveCollection(): BehaviorSubject<any> {
    console.log("Retrieving Active Collection from GDS")
    console.log("returning:")
    console.log(this.activeCollection);
    return this.activeCollection;
  }

  publishActiveClub(data: any) {
    console.log("GlobalDataService--> Publishing Active Club")
    this.localStorage.set("activeClub",data);
    this.activeClub.next(data);
  }
  getActiveClub(): BehaviorSubject<any> {
    console.log("Retrieving Active Club from GDS")
    console.log("returning:")
    console.log(this.activeClub);
    return this.activeClub;
  }

  publishRecalculateTradeOptions(data: any) {
    this.recalculateTradeOptions.next(data);
  }
  getRecalculateTradeOptions(): BehaviorSubject<boolean|null> {
    return this.recalculateTradeOptions;
  }

  publishDeviceId(data: any) {
    this.localStorage.set('exmeDeviceId', data);
    this.deviceId.next(data);
    this.devId = data;
  }
  getdeviceId(): Subject<any> {
    return this.deviceId;
  }
  getdeviceIdFromStorage(): Promise<any> {
    return this.localStorage.get('exmeDeviceId');
  }

  getAgentFromStorage(): Promise<any> {
    return this.localStorage.get('agent');
  }

/*
  async getAgentFromStorage() {
    await this.localStorage.get('agent').then(
      (ag) => {
        console.log('Storage Returned');
        console.log(ag);
        if ( ag !== undefined && ag !== null ) {
          if (Array.isArray(ag)) {
            // IS ARRAY --> CONSIDER ELEMENT [0]
            this.loadAgent(ag[0]);
          } else {
            // IS NOT ARRAY -> use value
            this.loadAgent(ag);
          }
        } else {
          //this.publishAgent(null);
          console.log('no agent -- first login, or logged out');
        }
      }
    );
  }
*/

  loadAgent(data: any) {
    if ( data == null ) {
      data ={};
      this.publishAgentId(null); 
      this.agentEmail.next(null);
      this.userAvatar.next(null);
      this.userTradeRating.next(null);
      this.numEvals.next(null);
      return;
  }
    this.localStorage.set('agent', data);
    if (Object.getPrototypeOf(data) === Object.prototype) {
      console.log("In prototype branch");
      this.agent.next(data);
      console.log("Data");
      console.log(data);
      console.log("LoadAgent Data");
      console.log(this.agent);
      }
    if ( data !== undefined && data !== null && Object.keys(data).length !== 0 && Object.getPrototypeOf(data) === Object.prototype ) {
      console.log("Success: Setting Data");
      this.publishAgentId(data.AGENT_ID);
      this.agentEmail.next(data.EMAIL);
      this.userAvatar.next(data.AVATAR);
      this.userTradeRating.next(data.trade_rating);
      this.numEvals.next(data.num_trade_evaluations);
      } else {
        console.log("unsetting!!")
        this.publishAgentId(null); 
        this.agentEmail.next(null);
        this.userAvatar.next(null);
        this.userTradeRating.next(null);
        this.numEvals.next(null);
      }
  }

  publishAgent(data: any) {
    if (Array.isArray(data)) {
      this.loadAgent(data[0]);
    } else {
      this.loadAgent(data);
    }
  }

  getAgent(): BehaviorSubject<any> {
    return this.agent;
  }

  publishAgentId(data: any) {
    if (data === undefined || data == null) {
      console.log("Skipping publishing Agent Id as data is undefined or null")
    }
    else {
      this.localStorage.set('exmeAgentId', data);
      this.agentId.next(data);
    }
  }
  getAgentId() {
    return this.agentId;
  }

  publishAgentLanguage(data: any) {
    this.localStorage.set('exmeAgentLanguage', data);
    this.agentLanguage.next(data);
  }
  getAgentLanguage() {
    return this.agentLanguage;
  }

  publishBrandNewAgent(data: any) {
    this.localStorage.set('brandNewAgent', data);
    this.brandNewAgent.next(data);
  }
  getBrandNewAgent() {
    return this.brandNewAgent;
  }

  publishAgentEmail(data: any) {
    this.agentEmail.next(data);
  }
  getagentEmail(): BehaviorSubject<any> {
    return this.agentEmail;
  }

  publishuserAvatar(data: any) {
    this.userAvatar.next(data);
  }
  getuserAvatar(): BehaviorSubject<any> {
    return this.userAvatar;
  }

  publishUserTradeRating(data: any) {
    this.userTradeRating.next(data);
  }
  getUserTradeRating(): BehaviorSubject<any> {
    return this.userTradeRating;
  }

  publishNumEvaluations(data: any) {
    this.numEvals.next(data);
  }
  getNumEvaluations(): BehaviorSubject<any> {
    return this.numEvals;
  }


  publishConversationId(data: any) {
    this.conversationId.next(data);
  }
  getConversationId(): BehaviorSubject<any> {
    return this.conversationId;
  }

  publishfirstName(data: any) {
    this.firstName.next(data);
  }
  getfirstName(): Subject<any> {
    return this.firstName;
  }

  publishlastName(data: any) {
    this.lastName.next(data);
  }
  getlastName(): Subject<any> {
    return this.lastName;
  }

  publishevaluations(data: any) {
    this.evaluations.next(data);
  }
  getevaluations(): Subject<any> {
    return this.evaluations;
  }

  publishdeviceCountry(data: any) {
    if (data!== undefined){
      this.deviceCountry.next(data.slice(-2));
      this.localStorage.set('exmeDeviceCountry', data.slice(-2));
    }
  }
  getdeviceCountry(): BehaviorSubject<any> {
    return this.deviceCountry;
  }

  publishHelpAccessMethod(data: any) {
    this.helpAccessMethod.next(data);
  }
  getHelpAcccessMethod(): BehaviorSubject<any> {
    return this.helpAccessMethod;
  }

  publishUserCountry(data: any) {
    this.userCountry.next(data);
    this.localStorage.set('exmeUserCountry', data);
  }
  getUserCountry(): Subject<any> {
    return this.userCountry;
  }


  publishdeviceLanguage(data: any) {
    this.deviceLanguage.next(data);
    this.localStorage.set('exmeDeviceLanguage', data);
  }
  getdeviceLanguage(): BehaviorSubject<any> {
    return this.deviceLanguage;
  }

  publishagentScore(data: any) {
    this.agentScore.next(data);
  }
  getagentScore(): Subject<any> {
    return this.agentScore;
  }


  logout(clear: boolean) {
    console.log('running logout gds function: clear is - ' + (clear?'TRUE':'FALSE'));
    this.publishAgent(null);
    this.publishAgentId(null);
    this.publishSessionToken(null);
    this.publishUserKeyPass({email: '', password: ''});
    this.router.navigate(['/login']);
    if (clear) {
      this.localStorage.clear().then(()=>{});
    }
  }


  /*
  updateCountry() {
    this.localStorage
    .get('agent')
    .then((agent) => {
      if (! (agent == null || agent[0] === undefined)) {
        if ( (agent[0].country_cca2 === 'ZZ' || agent[0].country_cca2 === undefined ) &&  this.deviceCountry !== undefined) {
          const agentUpdateBody = {resource: [{country_cca2: this.deviceCountry}]};
          this.agentsService.saveAgent(agentUpdateBody, agent[0].AGENT_ID)
          .subscribe( () => {
            this.publishUserCountry(this.deviceCountry);
          });
        }
        this.userAvatar.next(agent[0].AVATAR);
        this.firstName.next(agent[0].FIRST_NAME);
        this.lastName.next(agent[0].LAST_NAME);
        this.evaluations.next(agent[0].num_trade_evaluations);
        if (this.userAvatar == null ) {
          const avt = 'https://ui-avatars.com/api/?background=204F61&color=fff&font-size=0.4&name=' +
          (this.firstName + ' ' + this.lastName).replace(' ', '+');
          this.userAvatar.next(avt);
        }
        this.agentScore.next(agent[0].trade_rating);
      }
    });
  }
*/

  public async proposeGoToMaket (marketPackage: string) {
    let alert = await this.alertCtrl.create({
    header: this.translate.instant('NEW_APP_VERSION_AVAILABLE'),
    subHeader:  this.translate.instant('CURR_VERSION_NOT_SUPPORTED_PLS_UPD'),
    buttons: [
        {
        text: this.translate.instant('UPDATE'),
        handler: data => {
          console.log('New Terms Accepted');
          this.market.open(marketPackage);
        },
      },
        {
        text: this.translate.instant('ADD_ENTRY_CANCEL'),
        handler: data => {
          console.log('Cancel Clicked');
        },
          role: 'cancel'
        }
    ]
    });
    alert.present();
  }



  getStoredCollectionData(): Promise<any> {
    return this.localStorage.get('collectionData');
  }
  async setStoredCollectionData(id: string , data: any) {
    let val = await this.localStorage.get('collectionData');
    if (val == null) {
      val = [ {user_collection_id: id , fullList: data , last_acess: Date.now() } ];
    }
    else {
      var i = 0 ; 
      var found = false;
      for (; i < val.length; i++ ) {
        if (val[i].user_collection_id == id) {
          val[i].fullList = data;
          val[i].last_acess = Date.now();
          found = true;
        }
      }
      if (found == false) {
        val.push({user_collection_id: id , fullList: data , last_acess: Date.now()})
      }
    }
    this.localStorage.set('collectionData',val).then(()=> {});
  }

  getStoredCollectionList(): Promise<any> {
    return this.localStorage.get('collectionList');
  }
  async setStoredCollectionList(data: any) {
    this.localStorage.set('collectionList',data).then(()=> {});
  }

} 
