/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, MenuController, AlertController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
//import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
//import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import OneSignal from 'onesignal-cordova-plugin';

import { Storage } from '@ionic/storage-angular';
import { v4 as uuidv4 } from 'uuid';

import { TranslateService } from '@ngx-translate/core';

import { common, environment, version } from '../environments/environment';
import { AgentsService } from './providers/crm-agent-provider';
import { EncriptionService } from './providers/encription.service';
import { GlobalDataService } from './providers/global-data.service';
import { GuestService } from './providers/guest.service';
import { AdsServicesService } from './providers/ads-services.service';
import { MessagingServicesProvider } from './providers/messaging-services';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { ThemeService } from './providers/theme.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;

  userAvatar = '';
  firstName = 'X';
  lastName = 'M';
  agentScore = 0;
  evaluations = 0;
  tradeRating = 0;

  userLang = 'en';
  key: any;
  loginType: any;
  agentByDevice: any;
  agentFromStorage: any;
  bitmask: number;

  isAnonymous = false;

  public appPages = [];
  onPauseSubscription: any;
  onResumeSubscription: any;

  constructor(
    private platform: Platform,
//    private splashScreen: SplashScreen,
//    private statusBar: StatusBar,
//    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private badge: Badge,
    private router: Router,
    private globalization: Globalization,
    private ga: GoogleAnalytics,
    public localStorage: Storage,
    private agentsService: AgentsService,
    public translate: TranslateService,
    private gds: GlobalDataService,
    private uniqueDeviceID: UniqueDeviceID,
    private menu: MenuController,
    private crypto: EncriptionService,
    private guestSrvc: GuestService,
    private ads: AdsServicesService,
    public messagingServices: MessagingServicesProvider,
    private   loadingCtrl: LoadingController,
    private theme: ThemeService
  ) {

    this.startObserving();
    this.gds.publishdeviceCountry('PT');

    this.appPages = [
      {
        title: 'COLLECTIONS',
        url: '/col-list/full-list',
        icon: 'albums',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME-Icon-col.svg',
      },
      {
        title: 'ADD_COLLECTION',
        url: '/add-collection',
        icon: 'add',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME-Icon-col.svg',
      },
      {
        title: 'ADMIN_COLLECTION',
        url: '/col-admin',
        icon: 'paper-plane',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME_Icon-exme.svg'
      },
      {
        title: 'USER_MESSAGES',
        url: '/chat',
        icon: 'heart',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME_Icon-msg.svg'
      },
      {
        title: 'GROUPS',
        url: '/clubs',
        icon: 'archive',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME_Icon-groups.svg'
      },
      {
        title: 'PREFERENCES_PAGE',
        url: '/preferences',
        icon: 'trash',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME_Icon-settings.svg'
      },
      {
        title: 'APP_MESSAGES',
        url: '/news',
        icon: 'warning',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME_Icon-news.svg'
      },
      {
        title: 'QR_CODE',
        url: '/qr-code',
        icon: 'heart',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME_Icon-qrcode.svg'
      },
      {
        title: 'HISTORY',
        url: '/history',
        icon: 'heart',
        badgeValue : null,
        hide : false,
        svg: '/assets/icon/ExME-Icon-col.svg'
      }
    ];

    this.initializeApp();
  }

  async ngOnInit() {
    await this.localStorage.create();
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      console.log('Going in here');
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    this.theme.chooseTheme(localStorage.getItem("theme"))
  }

  logout(clear: boolean) {
    this.gds.logout(clear);
  }

  getUserKeyPassFromStorage(iteration: number) {
        this.gds.getUserKeyPassFromStorage().then(
           (key) => { this.bitmask+=1; console.log('APP COMP INIT- Got getUserKeyPassFromStorage='+key) ; this.key = key;} ,
           (err) => {  if (iteration < 6)  {setTimeout(()=> {this.getUserKeyPassFromStorage(iteration + 1);} , 100);} else {this.bitmask +=1;};
          }
        );
  }

  getUserLoginTypeFromStorage(iteration: number) {
    this.gds.getUserLoginTypeFromStorage().then(
      (tp) => { this.bitmask+=2; console.log('APP COMP INIT- Got getUserLoginTypeFromStorage='+tp) ; this.loginType = tp;}
      ,
      (err) => {  if (iteration < 6)  {setTimeout(()=> {this.getUserLoginTypeFromStorage(iteration + 1);} , 100);} else {this.bitmask +=2;};
     }    );

  }

  async initializeAdMob() {
    await this.ads.initializeAdMob();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      console.log(this.platform.platforms());
      if ( version.buildPlatform === 'cordova' ) {
        StatusBar.setOverlaysWebView({ overlay: false });
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.show();
      }
      SplashScreen.hide();
      this.initializeAdMob();
      //this.ads.evtShowBannerAdd();
      if ( version.buildPlatform === 'cordova' ) {
        this.OneSignalInit();
      }
      if (this.platform.is('ios')) {
        this.requestPermission();
      }

      this.loadDeviceId();
      this.getLocales();
      this.gds.getAgentFromStorage().then(
        (agent: any) => {
          console.log('Storage Returned');
          console.log(agent);
          if ( agent !== undefined && agent !== null ) {
            if (Array.isArray(agent)) {
              // IS ARRAY --> CONSIDER ELEMENT [0]
              this.gds.loadAgent(agent[0]);
            } else {
              // IS NOT ARRAY -> use value
              this.gds.loadAgent(agent);
            }
          } else {
            //this.publishAgent(null);
            console.log('no agent -- first login, or logged out');
          }
          if (agent !== undefined && agent !== null && agent.AGENT_ID !== undefined && agent.AGENT_ID !== null
          //&& agent !== {}
          ){
          console.log('Writing');
          this.gds.publishAgent(agent);
          this.gds.publishAgentId(agent.AGENT_ID);
          this.gds.publishAgentEmail(agent.EMAIL);
          this.gds.publishAgentLanguage(agent.LANGUAGE_ID);
          }
          else {
            console.log('Unexpected Agent from storage is:' );
            console.log(agent);
            this.gds.publishAgentLanguage(1);
            this.router.navigate(['/login']);
          }
        },
        (err) => {
          console.error('Error on getAgentFromStorage');
          console.error(err);
          this.router.navigate(['/login']);
        }
      );
      console.log('Init Step 1');
      this.bitmask = 0;
      setTimeout(()=> { this.getUserKeyPassFromStorage(1); }, 50);
      setTimeout(()=> { this.getUserLoginTypeFromStorage(1); }, 70);
      this.gds.getdeviceId().subscribe(
        (uuid) => {
          this.bitmask+=4;
          console.log('APP COMP INIT- Got uniqueDeviceID='+uuid) ;
          this.guestSrvc.getCrmGuestAgentByDeviceId(uuid).subscribe( (agt: any) => { this.bitmask+=8; console.log('Got getCrmAgentByDeviceId='+agt) ; this.agentByDevice = agt.resource[0];}); },
        (error) => {
          if ((error.status !== undefined && error.status !== null && error.status === 401) || (error.status_code !== undefined && error.status_code !== null && error.status_code === 401)) {
            this.gds.logout(false);
          }


        }
      ) ;

      this.onPauseSubscription = this.platform.pause.subscribe(() => {
        // do stuff
       });

     this.onResumeSubscription = this.platform.resume.subscribe(async () => {
      let agentId='';
      let token='';
      console.log('### 20230606 ###  - resuming platform');
       const loading = await this.loadingCtrl.create({
        showBackdrop: true,
        spinner: 'bubbles'
        });
      loading.present();
      this.gds.getAgentId().subscribe((data) => {
        agentId = data;
      });
      this.gds.getSessionToken().subscribe(
        (localToken: string) => {
          token =localToken;
        }
      );

      setTimeout(() => {
        if (agentId === '' || agentId === null) {
          this.router.navigate(['/col-list']);
          loading.dismiss();
        } else {
          loading.dismiss();
        }
        if (token === '' || token === null) {
          this.router.navigate(['/col-list']);
          loading.dismiss();
        } else {
          loading.dismiss();
        }
      }, 500);
       console.log('### 20230606 ###  - resumed platform');
     });
      /*
      this.gds.getAgentFromStorage().then( (agt) => {
        this.bitmask+=16;
        console.log("APP COMP INIT- Got getAgentFromStorage="+agt);
        this.agentFromStorage = agt
      });
      */

//      setTimeout(()=>{this.testData(1)},100);
/*
        this.gds.getUserKeyPassFromStorage().then(
          (key) => {
            console.log("Got Key!")
            if (key !== null) {
              console.log('ok temos valores de login:');
              console.log(key);
              key.password = this.crypto.decrypt(key.password, common.encryptKey);
              console.log(key);
              if ( key.email !== null && key.email !== '' && key.email !== undefined ) {
                this.getUserSession(key);
              }
            }
            else {
              console.log("No login data")
              this.gds.getUserLoginTypeFromStorage().then(
                (tp) => {
                  console.log("User Login Type = " + tp)
                  if (tp === 'guestUser' || tp === 'trialUser') {
                    this.gds.getAgentFromStorage().then(
                      (agent:any) => {
                        if (agent === null || agent === {} ) {
                          if (tp == 'guestUser') {
                            this.uniqueDeviceID.get().then(
                              (uuid) => {
                                this.guestSrvc.getCrmAgentByDeviceId(uuid).subscribe(
                                  (agt: any) => {
                                    console.log("Loading Agent data from Device Id")
                                    this.gds.publishAgent(agt);
                                    this.gds.publishAgentId(agt.AGENT_ID);
                                    this.gds.publishAgentEmail(agt.EMAIL)
                                    this.gds.publishAgentLanguage(agt.LANGUAGE_ID);
                                  }
                                )
                              }
                            )
                          }
                        } else {
                            this.gds.publishAgentId(agent.AGENT_ID);
                            this.gds.publishAgentEmail(agent.EMAIL);
                            this.gds.publishAgentLanguage(agent.LANGUAGE_ID);
                        }
                      }
                    ).finally(() => {
                      this.router.navigate(['/col-list']);
                    });

                  }
                  else {
                    console.log('no data for login available on storage');
                    this.router.navigate(['/login']);
                  }
                }
              )
            }
          },
          (error) => {
            this.router.navigate(['/login']);
          }
        );

*/
      console.log('Init Step Out');


    });
  }

/*  testData(iteration: number) {
    console.log('AMPM in testData');
    console.log(this.bitmask);
    if (this.bitmask !== 31 && iteration < 20 ) {
      setTimeout(() => {
        this.testData(iteration + 1);
      }, 100);
      return;
    }
    if ( this.bitmask !== 31) {
      // no data
      console.log('Navigating to Login from testData func');
      this.router.navigate(['/login']);
    }

    if (this.key !== null && this.key !== undefined) {
      console.log('ok temos valores de login:');
      console.log(this.key);
      this.key.password = this.crypto.decrypt(this.key.password, common.encryptKey);
      console.log(this.key.password);
      if ( this.key.email !== null && this.key.email !== '' && this.key.email !== undefined ) {
        this.getUserSession(this.key);
        // this.getUserSession enters col-list
        return;
      }
    }

      console.log('User Login Type = ' + this.loginType);
      if (this.loginType === 'guestUser' || this.loginType === 'trialUser') {
            if (this.agentFromStorage === undefined || this.agentFromStorage === null
              //|| this.agentFromStorage === {}
              ) {
              console.log('publishing data');
              console.log(this.agentByDevice);
              this.gds.publishAgent(this.agentByDevice);
              this.gds.publishAgentId(this.agentByDevice.AGENT_ID);
              this.gds.publishAgentEmail(this.agentByDevice.EMAIL);
              this.gds.publishAgentLanguage(this.agentByDevice.LANGUAGE_ID);
              console.log('Navigating to col-list from guestUser');
              this.router.navigate(['/col-list']);
            } else {
              console.log('Must Get Trial or Guest Agent');
              this.router.navigate(['/login']);
            }
      }
      else {
        console.log('Navigating to login from guestUser as loginType not defined');
        this.router.navigate(['/login']);
      }

  }
*/
  async loadDeviceId() {

    const uid = await this.gds.getdeviceIdFromStorage();

    if ( uid === null ) {
      this.uniqueDeviceID.get()
      .then((uuid: any) => {
        console.log('OBTIDO DEVICE ID - CORDOVA');
        this.gds.publishDeviceId(uuid);
      })
      .catch((error: any) => {
        console.log(error);
        console.log('OBTER DEVICE ID de NPM UUID');
        this.gds.publishDeviceId(uuidv4());
      });
    } else {
      console.log('OBTIDO DEVICE ID - DA STORAGE');
      this.gds.publishDeviceId(uid);
    }

  }

  getLocales() {
    this.translate.setDefaultLang('en');

    this.globalization.getPreferredLanguage()
    .then(res => {
      console.log('Globalization User Lang -> ' + res.value);
      this.gds.publishdeviceLanguage(res.value);
      this.userLang = /(de|en|pt)/gi.test(res.value) ? res.value : 'en';
      this.translate.use(this.userLang.toLowerCase());
    })
    .catch(e => {
      console.log('Not possible to get Language - getting from browser default');
      const lang = navigator.language.split('-')[0];
      console.log('Navigator language -> ' + lang);
      this.gds.publishdeviceLanguage(lang);
      this.userLang = /(de|en|pt)/gi.test(lang) ? lang : 'en';
    });

    this.globalization.getLocaleName()
    .then(res => {
      console.log('Globalization Locale -> ' + res.value);
      this.gds.publishdeviceCountry(res.value);
    })
    .catch(e => {
      console.log('Not possible to get Locale - getting from browser default');
      const locale = navigator.language.split('-')[1];
      console.log('Navigator language -> ' + locale);
      this.gds.publishdeviceCountry(locale);
    });

  }

  startObserving() {
    console.log('-------------OBSERVING GLOBAL VARS--------------------');
    /* Teste do serviço para obter vars globais */
    this.gds.getObservable().subscribe((data: any) => {
      console.log('Data received Observable', data);
      if ( data.refreshBadgesMenu!== undefined && data.refreshAgentId !== undefined && data.refreshAgentId !== null && data.refreshAgentId !== '' ) {
          console.log('Refreshing Badge Numbers on menu for agentId ' + data.refreshAgentId );
          this.agentsService.getCrmAgentPendingActions(data.refreshAgentId).subscribe(async (pendActions: any) => {
            if ( ! ( pendActions==null || pendActions.resource === undefined || pendActions.resource[0] == null ) ) {
              const pendingActions = pendActions.resource[0];
              console.log('pendingActions');
              console.log(pendingActions);
              this.appPages[0].badgeValue = (pendingActions.numPendingTradeRequest===0?null:pendingActions.numPendingTradeRequest);
              this.appPages[1].badgeValue = null;
              if ( pendingActions.numPendingDelegations === 0 ) {
                this.appPages[2].badgeValue = null;
              } else {
                this.appPages[2].badgeValue = pendingActions.numPendingDelegations;
                this.appPages[2].hide = false;
              }
  //            this.appPages[2].badgeValue = (pendingActions.numPendingDelegations==0?null:pendingActions.numPendingDelegations);
              this.appPages[4].badgeValue = (pendingActions.numGroupsPending===0?null:pendingActions.numGroupsPending);
              console.log('setting group value to '+ this.appPages[4].badgeValue );
              this.appPages[5].badgeValue = null;
              this.appPages[3].badgeValue = (pendingActions.user_messages_pending===0?null:pendingActions.user_messages_pending);
              this.appPages[6].badgeValue = (pendingActions.messages_pending?'1':null);
              const locTotalBadges = pendingActions.numPendingTradeRequest + pendingActions.numPendingDelegations + pendingActions.numGroupsPending + pendingActions.user_messages_pending + pendingActions.messages_pending;
              this.badge.set(locTotalBadges);
              console.log('Menu Badges Updated to:' + locTotalBadges);
              if (pendingActions.terms_accept_version	 !== pendingActions.current_terms_version) {
                const alert = await this.alertCtrl.create({
                  header: this.translate.instant('TERMS_CHANGED_TITLE'),
                  subHeader:  this.translate.instant('TERMS_CHANGED_SUBTITLE'),
                  buttons: [
                      {
                      text: this.translate.instant('ACCEPT_NEW_TERMS'),
                      handler: (data2) => {
                        console.log('New Terms Accepted');
                        this.agentsService.acceptNewTerms(pendingActions.agent_id, pendingActions.current_terms_version)
                          .subscribe( ()=> {console.log('Success Updating Terms');},
                                      (error)=> {console.log('Error Updating Terms'); console.log(error);} );
                        }
                      },
                      {
                      text: this.translate.instant('CHECK_NEW_TERMS'),
                      handler: (data3) => {
                        console.log('Checking New Terms');
                        this.router.navigateByUrl('/preferences/terms-of-use/en');
                        }
                      },

                  ],
                  cssClass: "alert-buttons"
                });
                alert.present();

              }
            }
          },
          (error) => {
            console.log('error is:');
            console.log(error);
            console.log(error.status);
            console.log(error.status_code);
            console.log(error.error);
            if ((error.status !== undefined && error.status != null && error.status === 401) ) {
              console.log('In if...');
              this.gds.logout(false);
            }
            this.appPages[0].badgeValue = null;
            this.appPages[1].badgeValue = null;
            this.appPages[2].badgeValue = null;
            this.appPages[3].badgeValue = null;
            this.appPages[4].badgeValue = null;
            this.appPages[5].badgeValue = null;
            this.appPages[6].badgeValue = null;
  //          this.appPages[5].badgeValue = null;
            this.badge.clear();
          }
          );
      } else {
        console.log('Observable Data is undefined');
      }
      }
    );

    this.gds.getuserAvatar().subscribe( (data) => {
      this.userAvatar = data;
      console.log('APP COMPONENT GET USER AVATAR RETURNED:' + data);
    });

    this.gds.getUserTradeRating().subscribe( (data) => {
      this.tradeRating = data;
      console.log('APP COMPONENT GET USER TRADE RATING RETURNED:' + data);
    });

    this.gds.getNumEvaluations().subscribe( (data) => {
      this.evaluations = data;
    });

    this.gds.getdeviceLanguage().subscribe((data: any) => {
      console.log('Reload do Menu na lingua certa....');
      console.log('loading menu for: ' + data);
      if ( data.lang !== undefined ) {
        this.translate.use(data.lang.toLowerCase());
      } else {
        if (data !== undefined && data != null) {
          this.translate.use(data.toLowerCase());
        } else {
          console.log('Não Temos ainda lingua definida....');
        }
      }
    });

    this.gds.getAgentFromStorage().then(
      (agent: any) => {
        console.log('Storage Returned');
        console.log(agent);
        if ( agent !== undefined && agent !== null ) {
          if (Array.isArray(agent)) {
            // IS ARRAY --> CONSIDER ELEMENT [0]
            this.gds.loadAgent(agent[0]);
          } else {
            // IS NOT ARRAY -> use value
            this.gds.loadAgent(agent);
          }
        } else {
          //this.publishAgent(null);
          console.log('no agent -- first login, or logged out');
        }
      }
    );

    this.gds.getdeviceCountry().subscribe((data) => {
      console.log('Device Country OBSERVABLE', data);
    });

    this.gds.getdeviceLanguage().subscribe((data) => {
      console.log('Device Language OBSERVABLE', data);
    });

    this.gds.getdeviceId().subscribe((data) => {
      console.log('Device Id OBSERVABLE', data);
    });

    this.gds.getAgentId().subscribe((data) => {
      console.log('Agent Id  OBSERVABLE', data);
    });

    this.gds.getagentEmail().subscribe((data) => {
      console.log('Agent Email OBSERVABLE', data);
      if ( data === 'ExMEAnonymousAccount@exme.club' ) {
        this.isAnonymous = true;
      } else {
        this.isAnonymous = false;
      }
    });

    this.gds.getBadges().subscribe((data) => {
      for (const i of [0, 1, 2, 3, 4, 5, 6] ) {
        if ( this.appPages[i] !== undefined ) {
          this.appPages[i].badgeValue = data[i];
        }
      }
    });

  }

  /* OLD OneSignal Code
  initializeOneSignal() {
    this.oneSignal.startInit('aea12ae3-de12-44cb-9f0e-119ba39fcb82', '572454532437');
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    this.oneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
      this.badge.increase(1);
    });
    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });
    this.oneSignal.endInit();
  }
  */

  // Call this function when your app starts
  OneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);
    if (this.platform.is('cordova')) {
      // NOTE: Update the setAppId value below with your OneSignal AppId.
      OneSignal.setAppId('aea12ae3-de12-44cb-9f0e-119ba39fcb82');
      OneSignal.setNotificationOpenedHandler(function(jsonData) {
          console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
          this.badge.increase(1);
      });

      // Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
      OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
          console.log('User accepted notifications: ' + accepted);
      });
    }

  }


  initializeGoogleAnalytics() {
    this.ga.startTrackerWithId(common.gaProperty)
    .then(() => {
      console.log('Google analytics is ready now');
      this.ga.trackView('application_initialized');
    })
    .catch(e => console.log('Error starting GoogleAnalytics', e));
  }

  getUserSession(sessionCredentials: any) {
    this.agentsService.getUserSession(sessionCredentials).subscribe(
      (session) => {
        console.log('-------Step 2--------');
        console.log(session);
        if (this.platform.is('cordova')) {
          this.ga.trackEvent('login_page', 'login', 'success', 1).then(() => {});
        }
        this.gds.publishUserSession(session);
        // ver se é suficiente ou se precisamos voltar a "carregar" o agente na storage
        console.log('Navigating to col-list on function getUserSession');
        this.router.navigate(['/col-list']);
      },
      (err) => {
        console.log('Navigating to login on function getUserSession');
        this.router.navigate(['/login']);
      }
    );
  }

  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }

  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }
}
