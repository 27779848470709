/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
//import { Storage } from '@ionic/storage-angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { GlobalDataService } from '../providers/global-data.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

@Injectable()
export class AgentsService {

  userLogged: any;
  headers: HttpHeaders;

  constructor(
                public http: HttpClient,
                private gds: GlobalDataService,
                private router: Router,
                private alertCtrl: AlertController
                //public localStorage: Storage
                )
    {
      this.headers = new HttpHeaders()
      .append('Accept', 'application/json')
      .append('Content-Type', 'application/json' )
      //.append( 'X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey)
      ;

      // this.getSessionToken();
      this.gds.getAgentId().subscribe(
        (data: any) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Agent Id as data is undefined');
          }
          else {
            console.log('Setting X-Exme-Agent-Id value');
            console.log(data);
            // ampm 20230629
            //this.headers = this.headers.set('X-Exme-Agent-Id', data.toString());
            this.headers = this.headerSet( this.headers,'X-Exme-Agent-Id', data.toString());
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

      this.gds.getSessionToken().subscribe(
        (data: string) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Session Token as data is undefined');
          }
          else {
            console.log('X-ExME-Session-Token');
            // ampm 20230629
            //this.headers = this.headers.set('X-ExME-Session-Token', data);
            this.headers = this.headerSet( this.headers,'X-ExME-Session-Token', data);
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );
    }

    getSessionToken() {
      this.gds.getSessionToken().subscribe(
        (token: string) => {
          if ( token !== undefined && token !== '' ) {
            this.headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json' )
            //.append( 'X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey)
            .append('X-ExME-Session-Token', token);
          }
        }
      );
    }

    agentAllowsMails(email: string, agent_id: string) {
    let targetAgent: any;
    return new Observable(observer => {
    this.getCrmAgentByEmail(email, agent_id ).subscribe(
      (crmAgent: any) => {
        console.log('OK');
        targetAgent = crmAgent.resource[0];
        if (targetAgent === null || targetAgent === undefined ) {
          console.log('Returning TRUE');
          observer.next(true);
          observer.complete();
          return;
        }
        console.log(targetAgent);
        console.log('Returning ' +  targetAgent.allow_mail_contact);
        observer.next(targetAgent.allow_mail_contact);
        observer.complete();
        return;
      }, (err) => {
        targetAgent = null;
        console.log('Error');
        observer.next(false);
        observer.complete();
        return;
      });
    });
  }

  //MIGRATED TO SECURE
  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  getCrmAgentByEmail(userLoggedEmail: string, agent_id: string) {
/*    const url = environment.apiUrlExme + '_table/CRM_AGENT?filter=email%3D' + userLoggedEmail;
    console.log('Sending GET to URL:' + url );
    console.log(this.headers);
    return this.http.get(url, { headers: this.headers  }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/

//        .map((response: Response) => response.json());
    const postBody = {  theEmail: userLoggedEmail};
    return this.localMakeRequest('getCrmAgentByEmail',postBody,'ExMESecure','getCrmAgentByEmail',agent_id,false);
/*
    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    if (!this.headers.has('X-Exme-Agent-Id') || this.headers.get('X-Exme-Agent-Id') != agent_id){
      this.headers.set('X-Exme-Agent-Id',agent_id);
      this.gds.publishAgentId(agent_id);
    }
    let localHeader =  this.headers.append('X-Exme-Operation', 'getCrmAgentByEmail').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    if ( localHeader.get('X-ExME-Session-Token') === undefined || localHeader.get('X-ExME-Session-Token') == null || localHeader.get('X-ExME-Session-Token') == '' ) {
      this.sleep(1000);
    }
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/

}

  getCrmAgentByDeviceId(deviceId: string) {
    const postBody = { deviceId };
    return this.localMakeRequest('getCrmAgentByDeviceId',null,'ExMEPublic','getCrmAgentByDeviceId',null,false);
/*
    const url = `${environment.apiUrlExme}_table/CRM_AGENT?filter=device_id%3D${deviceId}`;
    console.log('Sending GET to URL:' + url );
    return this.http.get(url, { headers: this.headers  }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
 */
  }

  getCrmRemoteAgentById(agentId: string) {
    const postBody = { theAgentID : agentId};
    return this.localMakeRequest('getCrmRemoteAgentById',postBody,'ExMEPublic','getCrmRemoteAgentById',null,false);
/*
[getCrmRemoteAgentById]
target=_table/CRM_AGENT
method=get
service=ExMEdb
filterField[]=AGENT_ID##%3D##theAgentID
fields=preferred_location_1,preferred_location_2,preferred_location_3,trade_by_mail

    let url = environment.apiUrlExme + '_table/CRM_AGENT?filter=AGENT_ID%3D' + agentId;
    console.log('Sending GET to URL:' + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  */
//        .map((response: Response) => response.json());
  }

  getCrmAgentById(agentId: string) {
    const postBody = { agentId};
    return this.localMakeRequest('getCrmAgentById',postBody,'ExMESecure','getCrmAgentById',agentId,false);
/*
    let url = environment.apiUrlExme + '_table/CRM_AGENT?filter=AGENT_ID%3D' + agentId;
    console.log('Sending GET to URL:' + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  */
//        .map((response: Response) => response.json());
  }

  getApplicationData() {
    return this.localMakeRequest('getApplicationData',null,'ExMEPublic','getApplicationData',null,false);
/*
    let url = environment.apiUrlExme + '_table/CRM_APPLICATION?filter=APPLICATION_ID%3D1' ;
    console.log("Sending GET to URL:" + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//        .map((response: Response) => response.json());
  }
  getApplicationTermsAndPrivacyPolicy(lang: string) {
    const postBody = { termsLang : lang};
    return this.localMakeRequest('getApplicationTermsAndPrivacyPolicy',postBody,'ExMEPublic','getApplicationTermsAndPrivacyPolicy',null,false);
/*
    let url = environment.apiUrlExme + '_table/vActiveAplicationTerms?filter=(terms_language%3D'+lang+')';
    console.log("Sending GET to URL:" + url  );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//        .map((response: Response) => response.json());
  }

  getAgentHistoryPrizes(agentId: string) {
    const postBody = { agentId};
    return this.localMakeRequest('getAgentHistoryPrizes',postBody,'ExMESecure','getAgentHistoryPrizes',agentId,false);
  }
  getAgentHistoryBinders(agentId: string) {
    const postBody = { agentId};
    return this.localMakeRequest('getAgentHistoryBinders',postBody,'ExMESecure','getAgentHistoryBinders',agentId,false);
  }

  getApplicationAddsConfiguration() {
    return this.localMakeRequest('getApplicationAddsConfiguration',null,'ExMEPublic','getApplicationAddsConfiguration',null,false);
/*
    let url = environment.apiUrlExme + '_table/vAppAddsConfiguration';
    console.log("Sending GET to URL:" + url  );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/

//        .map((response: Response) => response.json());
  }

  getCrmAgentPendingActions(agentId: string) {
    const postBody = { agent_id : agentId};
    return this.localMakeRequest('getCrmAgentPendingActions',postBody,'ExMESecure','getCrmAgentPendingActions',agentId,false);

/*
    let url = environment.apiUrlExme + '_table/VAgentPendingActions?filter=agent_id%3D' + agentId;
    console.log("Sending GET to URL:" + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  */
/*
    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'getCrmAgentPendingActions').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//        .map((response: Response) => response.json());
  }

  createCrmAgent(agent: any){
    return this.localMakeRequest('createCrmAgent',agent,'ExMEPublic','createCrmAgent',null,false);
/*
    const body = JSON.stringify(agent);
    const url = environment.apiUrlExme + '_table/CRM_AGENT';

    return this.http.post(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }
/*
  registerLogin(agentId) {
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var formattedDate = date+' '+time;

    let body = {
      agent_id: agentId, login_time : formattedDate
    }
    let url = environment.apiUrlExme + '_table/CRM_AGENT_LOGINS';

    return this.http.post(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
//      .map((response: Response) => response.json())

  }
*/

  registerUser( newAgent: any) {
    return this.localMakeRequest('registerUser',newAgent,'ExMERegister','registerUser',null,true);
  }

  saveAgent(body: any, agentId: string) {
    body.agentId = agentId;
    return this.localMakeRequest('saveAgent',body,'ExMESecure','saveAgent',agentId,false);

/*    const url = `${environment.apiUrlExme}_table/CRM_AGENT?filter=AGENT_ID%3D${agentId}`;
    console.log('Sending PATCH to:' + url);
    console.log(body);
    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }


  acceptNewTerms(agent_id: string, terms_version: string) {
    const paramsBody = {params: [{name: 'p_agent_id',value:agent_id},{name: 'p_terms_version',value:terms_version},{name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('acceptNewTerms',paramsBody,'ExMESecure','acceptNewTerms',agent_id,false);

    /*
        let url = environment.apiUrlExme + '_proc/CRM_AGENT_ACCEPT_TERMS';
        return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
    */
//          .map((response: Response) => response.json());

  }
  setMaxMessage(agent_id: string, max_message_id: number) {
    const body ={  agentId: agent_id , resource:[{max_presented_app_message:max_message_id}]};
    return this.localMakeRequest('setMaxMessage',body,'ExMESecure','setMaxMessage',agent_id,false);
/*
    let url = environment.apiUrlExme +
      '_table/CRM_AGENT?filter=agent_id%3D' + agent_id;
    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())

  }

  getAgentMessages(lang, country) {
    const body = {  lang, country};
    return this.localMakeRequest('getAgentMessages',body,'ExMEPublic','getAgentMessages',null,false);
/*
    let url = environment.apiUrlExme + '_table/CRM_APPLICATION_MESSAGE?filter=(language_id%3D'+lang+')%20and%20(country_cca2%20in%20('+country+'%2CZZ))%20and%20(active%3D1)'+ '&limit=20&order=creation_time%20DESC';
    console.log("Sending GET to URL:" + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//        .map((response: Response) => response.json());

  }
  getAgentMessageControl(agentId: string) {
    const body = {  agentId};
    return this.localMakeRequest('getAgentMessageControl',body,'ExMESecure','getAgentMessageControl',agentId,false);
/*
    let url = environment.apiUrlExme + '_table/vAgentMessageControl?filter=agentId%3D' + agentId ;
    console.log("Sending GET to URL:" + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//        .map((response: Response) => response.json());

  }

  getFavoriteStatus(agent_id: string, target_agent_id: string) {
    const body = {  agentId: agent_id, targetAgentId: target_agent_id};
    return this.localMakeRequest('getFavoriteStatus',body,'ExMESecure','getFavoriteStatus',agent_id,false);
/*
    let url = environment.apiUrlExme + '_table/CRM_AGENT_FAVORITE?filter=(agent_id%3D' + agent_id + ')%20and%20(target_agent_id%3D' +target_agent_id+ ')' ;
    console.log("Sending GET to URL:" + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//        .map((response: Response) => response.json());
  }

  setFavoriteStatus(agent_id: string, target_agent_id: string, is_favorite: number) {
    const body = {agentId: agent_id, targetAgentId: target_agent_id,  resource:[{is_favorite}]};
    return this.localMakeRequest('setFavoriteStatus',body,'ExMESecure','setFavoriteStatus',agent_id,false);
/*
    let url = environment.apiUrlExme +
      '_table/CRM_AGENT_FAVORITE?filter=(agent_id%3D' + agent_id + ')%20and%20(target_agent_id%3D' +target_agent_id+ ')' ;
    console.log("URL:"+url)
    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
//      .map((response: Response) => response.json())
  }

  addFavoriteStatus(agent_id: string, target_agent_id: string, is_favorite: number) {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const formattedDate = date+' '+time;

    const insertBody = {resource: [{agent_id,target_agent_id,is_favorite,creation_date: formattedDate,modification_date: formattedDate}]};

    return this.localMakeRequest('addFavoriteStatus',insertBody,'ExMESecure','addFavoriteStatus',agent_id,false);
/*
    let url = environment.apiUrlExme + '_table/CRM_AGENT_FAVORITE';

    return this.http.post(url, insertBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  getUserFavorites(agent_id: string ) {
    return this.localMakeRequest('getUserFavorites',null,'ExMESecure','getUserFavorites',agent_id,false);
    /*
    let url = environment.apiUrlExme + '_table/vAgentFavorites?filter=(is_favorite=1)%20and%20(agent_id%3D' + agent_id + ')' ;
    console.log("Sending GET to URL:" + url );
    console.log("Sending with headers:" );
    console.log (this.headers);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
//        .map((response: Response) => response.json());
  }

  registerDF(body: any) {
    const url = environment.apiUrl + 'api/v2/user/register?login=true';
    // Doing the signup http post
    return this.http.post(url, body, {headers: this.headers}).pipe(
      map(this.extractData)//,
      //catchError(this.handleError)
    );
  }
/*
  resetPass(body: any) {
    const url = environment.apiUrl + 'api/v2/user/password?reset=true';
    // Doing the signup http post
    return this.http.post(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
*/

  getDFID(Email: string) {

//    return this.localMakeRequest('getDFID',{"email":Email},'ExMESecure','getDFID',null,false);

    const url = `${environment.apiUrl}api/v2/system/user?fields=id&filter=email%3D${Email}`;
    return this.http.get(url, {headers: this.headers}).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );
}

  getUserSession(body: any) {
      console.log('###20230613 - Running function###');
      return this.localMakeRequest('getSession',body,'ExMESession','getSession',null,false);

  }

  finalizeSalesTransaction(transaction_id: any, agentId: string) {
      const paramsBody = {params: [{name: 'p_qr_message',value:transaction_id},{name: 'p_agent_id',value:agentId}]};
      return this.localMakeRequest('finalizeSalesTransaction',paramsBody,'ExMESecure','finalizeSalesTransaction',null,false);
/*
[finalizeSalesTransaction]
target=_proc/EXME_FINALIZE_SALES_TRANSACTION
method=proc
service=ExMEdb
sessionField=p_agent_id
*/
    }

  validateEmail(email: any, agent_id: any) {

    const paramsBody = {
      params: [
        {name: 'p_email',
          value:email},
          {name: 'p_agent_id',
          value:agent_id},
          {name: 'p_confirmation_code',
          value:'@confirmationcode'},
        {name: 'p_name',
          value:'@name'},
          {name: 'p_lang',
            value:'@lang'},
        {name: 'p_output',
          value:'@output'}
      ]
    };

    return this.localMakeRequest('validateEmail',paramsBody,'ExMEPublic','validateEmail',agent_id.toString(),false);

  }

  sendResetPassEmail(email: any, agent_id: any) {

    const paramsBody = {
      params: [
        {name: 'p_email',
          value:email},
        {name: 'p_agent_id',
          value:agent_id},
        {name: 'p_confirmation_code',
          value:'@confirmationcode'},
        {name: 'p_name',
          value:'@name'},
          {name: 'p_lang',
            value:'@lang'},
        {name: 'p_output',
          value:'@output'}
      ]
    };

    return this.localMakeRequest('sendResetPassEmail',paramsBody,'ExMEPublic','sendResetPassEmail',agent_id.toString(),false);

  }


  changeEmail(new_email: any, email: any, agent_id: any) {

    const paramsBody = {
      params: [
        {name: 'p_email',
          value:new_email},
          {name: 'p_old_email',
          value:email},
          {name: 'p_agent_id',
          value:agent_id},
          {name: 'p_email_out',
          value:'@p_email_out'},
          {name: 'p_confirmation_code',
          value:'@confirmationcode'},
        {name: 'p_name',
          value:'@p_name'},
          {name: 'p_lang',
            value:'@p_lang'},
            {name: 'p_output',
              value:'@p_output'},
              {name: 'p_subject_request',
                value:'@p_subject_request'},
                {name: 'p_body_request',
                  value:'@p_body_request'}
      ]
};

    return this.localMakeRequest('changeEmail',paramsBody,'ExMESecure','changeEmail',agent_id.toString(),false);

  }

  deleteUser(email: any, agent_id: any) {

    const paramsBody = {params: [{name: 'p_email',value:email},{name: 'p_agent_id',value:agent_id},
                                  {name: 'p_email_out',value:'@p_email_out'},{name: 'p_confirmation_code',value:'@confirmationcode'},{name: 'p_name',value:'@name'},{name: 'p_lang',value:'@lang'},
                                  {name: 'p_output',value:'@output'}]};
    return this.localMakeRequest('deleteUser',paramsBody,'ExMESecure','deleteUser',agent_id.toString(),false);

  }


  generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  localMakeRequest(callFunction: string, body: any , service: string , operation: string, agent_id: string , deleteSession: boolean) {
    console.log('Post Body called from: ' + callFunction);
    console.log(JSON.stringify(body));

    const url = environment.apiUrl+ 'api/v2/' + service;
    console.log('Posting to URL: ' + url);
    // AMPM 20230629
    //let localHeader =  this.headers.append('X-Exme-Operation', operation).set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    let localHeader = this.headerSet(this.headers,'X-Exme-Operation', operation);
    console.log(localHeader);
    localHeader = this.headerSet(localHeader,'X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log(localHeader);
    if ( deleteSession ) {
      //localHeader = localHeader.delete('X-ExME-Session-Token');
      localHeader = this.headerSet(localHeader,'X-ExME-Session-Token',null);
      console.log(localHeader);
    }
    if ( agent_id != null && localHeader.get('X-Exme-Agent-Id') != agent_id ) {
      //localHeader = localHeader.delete('X-Exme-Agent-Id').set('X-Exme-Agent-Id',agent_id);
      localHeader = this.headerSet(localHeader,'X-Exme-Agent-Id',agent_id);
      console.log(localHeader);

    }
    console.log('Headers for call from ' + callFunction + ' are:');
    console.log(localHeader);
    return this.http.post(url, body, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );

  }

  private headerSet(sourceHeader: HttpHeaders, changeKey: string, changeValue: any){
    let retHeader=new HttpHeaders();
    sourceHeader.keys().forEach( (key) => {
      if ( key !== changeKey){
        retHeader=retHeader.append(key,sourceHeader.get(key));
      } else {
      }
    });
    if ( changeValue !== undefined && changeValue !== null && changeValue !== '') {
      retHeader=retHeader.append(changeKey,changeValue);
//      console.log('Appending Key');
    }
//    console.log('Returning:');
//    console.log(retHeader);
    return retHeader;
  }

  private handleError(error: HttpErrorResponse) {
    console.error('entering handleError function');
    console.error(JSON.stringify(error));
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `error message was: ${error.error.error.message}`);
    }
    return throwError(error);
  }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

}
