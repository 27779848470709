/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import { Storage } from '@ionic/storage';
import {HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { GlobalDataService } from '../providers/global-data.service';

@Injectable()
export class UserCollectionsServices {

  userLogged: any;
  headers: HttpHeaders;
  url: string;
  colTabsPage: any;
  agent: any;

  constructor(
                public http: HttpClient,
                private gds: GlobalDataService,
                public localStorage: Storage)
    {
      this.headers = new HttpHeaders();
      this.headers = this.headers.set('Content-Type', 'application/json');
      //this.headers = this.headers.set('X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey);
      this.gds.getAgentId().subscribe(
        (data: any) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Agent Id as data is undefined');
          }
          else {
            console.log('Setting X-Exme-Agent-Id value');
            console.log(data);
            this.headers = this.headerSet(this.headers,'X-Exme-Agent-Id', data.toString());
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

      this.gds.getSessionToken().subscribe(
        (data: string) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Session Token as data is undefined');
          }
          else {
            console.log('X-ExME-Session-Token');
            this.headers = this.headerSet(this.headers,'X-ExME-Session-Token', data);
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

      this.userLogged = localStorage.get('userSession');
      this.agent = localStorage.get('agent');

      this.getSessionToken();
    }

  getSessionToken() {
    this.gds.getSessionToken().subscribe(
      (token: string) => {
        if ( token !== undefined && token !== '' ) {
          this.headers = new HttpHeaders()
          .append('Accept', 'application/json')
          .append('Content-Type', 'application/json' )
          //.append( 'X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey)
          .append('X-ExME-Session-Token', token);
//          .append('X-ExME-Session-Token', token);
        }
      }
    );
  }

  getShippingMethodByCollection(collection_id: string){
    const postBody = {theCollectionId : collection_id };
    return this.localMakeRequest('getShippingMethodsByCollection',postBody,'ExMEPublic','getShippingMethodsByCollection',null,false);
}

  getShippingMethodById(id: string){
    const postBody = {theShippingCodeId : id };
    return this.localMakeRequest('getShippingMethodsById',postBody,'ExMEPublic','getShippingMethodsById',null,false);
  }


  getAllCollections( country: string,searchValue: string) {
/*
    let url = environment.apiUrlExme + '_table/COL_RD_COLLECTIONS?filter=visible%3D1&order=popularity%20DESC';
    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
//      .map((response: Response) => response.json())
*/

    const postBody = {  country: (country===undefined ||country==='ZZ'?'%':country), token: searchValue.toLowerCase()};
    return this.localMakeRequest('getAllCollections',postBody,'ExMEPublic','getAllCollections',null,false);

/*
    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMEPublic';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'getCollections').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/

  }

  getAllCollectionsByType( country: string,searchValue: string, collectionType: string) {

        const postBody = {
          country: (country===undefined ||country==='ZZ'?'%':country),
          token: searchValue.toLowerCase(),
          theCollectionType: collectionType};
        return this.localMakeRequest('getAllCollectionsByType',postBody,'ExMEPublic','getAllCollectionsByType',null,false);

      }

  getUserCollections(agentId: string) {
    /*let ret : any;

    let url = environment.apiUrlExme + '_table/vUserCollections?filter=agent_id%3D' + agentId + '&order=collection_popularity%20DESC%2Ccreation_time%20DESC';
//    let url = environment.apiUrlExme + '_table/vUserCollections?filter=agent_id%3D' + agentId ;
    console.log("Requesting Get for URL:" + url);
    ret = this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
//      .map((response: Response) => response.json());
    console.log("returning from getUserCollections")
    return ret;
    */


    const postBody = {  agent_id: agentId};
    return this.localMakeRequest('getUserCollections',postBody,'ExMESecure','getUserCollections',agentId,false);

/*
    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    if (!this.headers.has('X-Exme-Agent-Id') || this.headers.get('X-Exme-Agent-Id') != agentId){
      this.headers.set('X-Exme-Agent-Id',agentId);
      this.gds.publishAgentId(agentId);
    }
    let localHeader =  this.headers.append('X-Exme-Operation', 'getUserCollections').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  getPendingDelegationsReceived(agentId: string) {
    const postBody = { theAgentId: agentId};
    return this.localMakeRequest('getPendingDelegationsReceived',postBody,'ExMESecure','getPendingDelegationsReceived',agentId,false);
/*
[getPendingDelegationsReceived]
target=_table/vUserDelegations
method=get
service=ExMEdb
sessionField=delegated_agent_id
orderField=creation_time%20desc
filterField[]=delegated_agent_id##%3D##theAgentId
staticFilter="delegation_status%3D0"
*/
/*
    let url = environment.apiUrlExme + '_table/vUserDelegations?filter=(delegated_agent_id%3D' + agentId + ')%20and%20(delegation_status%3D0)' + '&order=creation_time%20DESC';
    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  getPendingDelegationsSent(agentId: string) {
    const postBody = { theAgentId: agentId};
    return this.localMakeRequest('getPendingDelegationsSent',postBody,'ExMESecure','getPendingDelegationsSent',agentId,false);
/*
[getPendingDelegationsSent]
target=_table/vUserDelegations
method=get
service=ExMEdb
sessionField=delegator_agent_id
orderField=creation_time%20desc
filterField[]=delegated_agent_id##%3D##theAgentId
staticFilter="delegation_status%3D0"
*/
/*
    let url = environment.apiUrlExme + '_table/vUserDelegations?filter=(delegator_agent_id%3D' + agentId + ')%20and%20((delegation_status%3D0))' + '&order=creation_time%20DESC';
    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  getHistoryDelegations(agentId: string) {


    const postBody = { theAgentId: agentId};
    return this.localMakeRequest('getHistoryDelegations',postBody,'ExMESecure','getHistoryDelegations',agentId,false);
/*
[getHistoryDelegations]
target=_table/vUserDelegations
method=get
service=ExMEdb
sessionField=delegated_agent_id
orderField=creation_time%20desc
filterField[]=delegated_agent_id##%3D##theAgentId
staticFilter="delegation_status%3D1"
*/
/*
    let url = environment.apiUrlExme + '_table/vUserDelegations?filter=(delegated_agent_id%3D' + agentId + ')%20and%20(delegation_status%3D1)' + '&order=creation_time%20DESC';
    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  getActiveDelegationsSource(agentId: string) {

    const postBody = { theAgentId: agentId};
    return this.localMakeRequest('getActiveDelegationsSource',postBody,'ExMESecure','getActiveDelegationsSource',agentId,false);
/*
[getActiveDelegationsSource]
target=_table/vUserActiveDelegations
method=get
service=ExMEdb
#sessionField=delegated_agent_id
sessionField=theAgentId
orderField=creation_time%20desc
filterField[]=delegator_agent_id##%3D##theAgentId
*/
/*
    const url = environment.apiUrlExme + '_table/vUserActiveDelegations?filter=((delegator_agent_id%3D' + agentId + ')%20or%20(delegated_agent_id%3D' + agentId + '))' + '&order=creation_time%20DESC';
    console.log('Requesting Get for URL:' + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  getActiveDelegationsTarget(agentId: string) {

    const postBody = { theAgentId: agentId};
    return this.localMakeRequest('getActiveDelegationsTarget',postBody,'ExMESecure','getActiveDelegationsTarget',agentId,false);
/*
[getActiveDelegationsTarget]
target=_table/vUserActiveDelegations
method=get
service=ExMEdb
sessionField=theAgentId
orderField=creation_time%20desc
filterField[]=delegated_agent_id##%3D##theAgentId
*/
/*
    const url = environment.apiUrlExme + '_table/vUserActiveDelegations?filter=((delegator_agent_id%3D' + agentId + ')%20or%20(delegated_agent_id%3D' + agentId + '))' + '&order=creation_time%20DESC';
    console.log('Requesting Get for URL:' + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }


  getUserCollectionsStatus(userCollectionId: string , agentId: string) {

    const postBody = { theAgentId: agentId, theUserCollectionId:userCollectionId};
    return this.localMakeRequest('getUserCollectionsStatus',postBody,'ExMESecure','getUserCollectionsStatus',agentId,false);
/*
[getUserCollectionsStatus]
target=_table/vUserCollections
method=get
service=ExMEdb
sessionField=agent_id
filterField[]=user_collection_id_key##%3D##theUserCollectionId
filterField[]=agent_id##%3D##theAgentId

    let url = environment.apiUrlExme + '_table/vUserCollections?filter=user_collection_id_key%3D' + userCollectionId;

    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  getUserCollectionDetails(userCollectionId: string, agentId: string){

    const postBody = { theAgentId: agentId, theUserCollectionId:userCollectionId};
    return this.localMakeRequest('getUserCollectionDetails',postBody,'ExMESecure','getUserCollectionDetails',agentId,false);
/*
    let fields = '?fields=item_id%2Csection_name%2Citem_name%2Corder_number%2Cuser_collection_id%2Ccollection_id%2Ctot%2CTenho%2CRepetidos%2Cnum_reserved%2Cicon_color%2Cdetail_record_exists'
    let filter = '&filter=(user_collection_id%3D' + userCollectionId + ')%20and%20(agent_id%3D' + agentId + ')'
    let url = environment.apiUrlExme + '_table/vUserCollectionItems7' + fields + filter

    console.log("Requesting Get for URL:" + url);

    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  // MIGRATED TO SECURE
  getUserCollectionDetailsSimple(userCollectionId: string, agentId: string){
/*
    let fields = '?fields=item_id%2Csection_name%2Citem_name%2Corder_number%2Cuser_collection_id%2Ccollection_id%2Ctot%2CTenho%2CRepetidos%2Cnum_reserved%2Cicon_color%2Cdetail_record_exists'
    let filter = '&filter=(user_collection_id%3D' + userCollectionId + ')%20and%20(agent_id%3D' + agentId + ')'
    let url = environment.apiUrlExme + '_table/vUserCollectionItemsSimple' + fields + filter

    console.log("Requesting Get for URL:" + url);

    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/
//      .map((response: Response) => response.json())
    const postBody = {  userCollectionId};
    return this.localMakeRequest('getUserCollectionDetailsSimple',postBody,'ExMESecure','getUserCollectionDetailsSimple',agentId,false);
/*
    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'getUserCollectionDetailsSimple').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure).set('X-Exme-Agent-Id',agentId);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
}

  // MIGRATED TO SECURE
  getUserCollectionItemDetails(userCollectionId: string, itemId: string, agentId: string){

/*    let fields = '?fields=item_id%2Csection_name%2Citem_name%2Corder_number%2Cuser_collection_id%2Ccollection_id%2Ctot%2CTenho%2CRepetidos%2Cnum_reserved%2Cicon_color%2Cdetail_record_exists'
    let filter = '&filter=(user_collection_id%3D' + userCollectionId + ')%20and%20(item_id%3D' + itemId + ')%20and%20(agent_id%3D' + agentId + ')'

    let url = environment.apiUrlExme + '_table/vUserCollectionItems7' + fields + filter

    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/
//      .map((response: Response) => response.json())
    const postBody = { agent_id: agentId, itemId ,userCollectionId};
    return this.localMakeRequest('getUserCollectionItemDetails',postBody,'ExMESecure','getUserCollectionItemDetails',agentId,false);
/*
    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'getUserCollectionItemDetails').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/

  }

  // MIGRATED TO SECURE
  getUserCollectionRangeDetails(userCollectionId: string, startOrderNum: number, endOrderNum: number, agentId: string){

/*    let fields = '?fields=item_id%2Csection_name%2Citem_name%2Corder_number%2Cuser_collection_id%2Ccollection_id%2Ctot%2CTenho%2CRepetidos%2Cnum_reserved%2Cicon_color%2Cdetail_record_exists'
    let filter = '&filter=(user_collection_id%3D' + userCollectionId + ')%20and%20(order_number<=' + endOrderNum + ')%20and%20(order_number>=' + startOrderNum + ')%20and%20(agent_id%3D' + agentId + ')'

    let url = environment.apiUrlExme + '_table/vUserCollectionItems7' + fields + filter

    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/
//      .map((response: Response) => response.json())
    const postBody = { agent_id: agentId, userCollectionId,endOrderNum,startOrderNum};

    console.log('Post Body...' + JSON.stringify(postBody));

    const url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url);
    const localHeader =  this.headers.append('X-Exme-Operation', 'getUserCollectionRangeDetails').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader);
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );


  }

  getCountExchangeOptions(agentUserColId: string , agentId: string ) {

//    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20((agent_number_gives%3E%200)%20or%20(agent_number_receives%3E%200)))';
//    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+'))';

    const postBody ={theAgentUserColId:agentUserColId,agentId};
    return this.localMakeRequest('getCountExchangeOptions',postBody,'ExMESecure','getCountExchangeOptions',agentId,false);

/*    const url = environment.apiUrlExme + '_table/vCountExchangeOptions?related=vAgentActiveGroups_by_agent_id&filter=(((orig_user_collection_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')))&order=agent_number_receives%20desc';

    console.log('Requesting Get for URL:' + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  getCountExchangeOptionsForUserCollection(agentUserColId: string , agentId: string, targetAgentId: string , targetCollectionId: string ) {

//    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20((agent_number_gives%3E%200)%20or%20(agent_number_receives%3E%200)))';
//    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+'))';
/*    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?related=vAgentActiveGroups_by_agent_id&filter=(((orig_user_collection_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20(target_id%3D'+targetAgentId+')%20and%20(target_user_col_id%3D'+targetCollectionId+')))&order=agent_number_receives%20desc';

    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/
//      .map((response: Response) => response.json())
    const postBody ={agentUserColId,agentId,targetAgentId,targetCollectionId};

    console.log('Post Body...' + JSON.stringify(postBody));

    const url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url);
    const localHeader =  this.headers.append('X-Exme-Operation', 'getCountExchangeOptionsForUserCollection').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader);
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );


}

getCountExchangeOptionsForUserCollection4Ways(agentUserColId: string , agentId: string, targetAgentId1: string , targetCollectionId1: string, targetAgentId2: string , targetCollectionId2: string, targetAgentId3: string , targetCollectionId3: string, targetAgentId4: string , targetCollectionId4: string ) {

  //    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20((agent_number_gives%3E%200)%20or%20(agent_number_receives%3E%200)))';
  //    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+'))';
  const postBody ={agentUserColId,agentId,targetAgentId:[targetAgentId1,targetAgentId2,targetAgentId3,targetAgentId4],targetCollectionId:[targetCollectionId1,targetCollectionId2,targetCollectionId3,targetCollectionId4]};
  return this.localMakeRequest('getCountExchangeOptionsForUserCollection4Ways',postBody,'ExMESecure','getCountExchangeOptionsForUserCollection',agentId,false);
  /*
  console.log('Post Body...' + JSON.stringify(postBody));

  const url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
  console.log('Posting to URL: ' + url);
  const localHeader =  this.headers.append('X-Exme-Operation', 'getCountExchangeOptionsForUserCollection').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
  console.log('Headers');
  console.log(localHeader);
  return this.http.post(url, postBody, {headers: localHeader}).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );
  */
  /*let url = environment.apiUrlExme + '_table/vCountExchangeOptions?related=vAgentActiveGroups_by_agent_id&filter=(((orig_user_collection_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20';
      url = url + '(((target_id%3D'+targetAgentId1+')%20and%20(target_user_col_id%3D'+targetCollectionId1+'))or((target_id%3D'+targetAgentId2+')%20and%20(target_user_col_id%3D'+targetCollectionId2+'))or((target_id%3D'+targetAgentId3+')%20and%20(target_user_col_id%3D'+targetCollectionId3+'))or((target_id%3D'+targetAgentId4+')%20and%20(target_user_col_id%3D'+targetCollectionId4+')))))&order=agent_number_receives%20desc';

      console.log('Requesting Get for URL:' + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );

  //      .map((response: Response) => response.json())
    }
  */
   }
   getCountExchangeOptionsForUserCollection16Ways(agentUserColId: string , agentId: string,
                                                  targetAgentId1: string , targetCollectionId1: string, targetAgentId2: string , targetCollectionId2: string,
                                                  targetAgentId3: string , targetCollectionId3: string, targetAgentId4: string , targetCollectionId4: string ,
                                                  targetAgentId5: string , targetCollectionId5: string, targetAgentId6: string , targetCollectionId6: string ,
                                                  targetAgentId7: string , targetCollectionId7: string, targetAgentId8: string , targetCollectionId8: string ,
                                                  targetAgentId9: string , targetCollectionId9: string, targetAgentId10: string , targetCollectionId10: string ,
                                                  targetAgentId11: string , targetCollectionId11: string, targetAgentId12: string , targetCollectionId12: string ,
                                                  targetAgentId13: string , targetCollectionId13: string, targetAgentId14: string , targetCollectionId14: string ,
                                                  targetAgentId15: string , targetCollectionId15: string, targetAgentId16: string , targetCollectionId16: string ) {

    //    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20((agent_number_gives%3E%200)%20or%20(agent_number_receives%3E%200)))';
    //    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+'))';
    const postBody ={agentUserColId,
                     agentId,
                     targetAgentId:[targetAgentId1,targetAgentId2,targetAgentId3,targetAgentId4,targetAgentId5,targetAgentId6,targetAgentId7,targetAgentId8,targetAgentId9,targetAgentId10,targetAgentId11,targetAgentId12,targetAgentId13,targetAgentId14,targetAgentId15,targetAgentId16],
                     targetCollectionId:[targetCollectionId1,targetCollectionId2,targetCollectionId3,targetCollectionId4,targetCollectionId5,targetCollectionId6,targetCollectionId7,targetCollectionId8,targetCollectionId9,targetCollectionId10,targetCollectionId11,targetCollectionId12,targetCollectionId13,targetCollectionId14,targetCollectionId15,targetCollectionId16]};
    return this.localMakeRequest('getCountExchangeOptionsForUserCollection16Ways',postBody,'ExMESecure','getCountExchangeOptionsForUserCollection',agentId,false);
    /*
    console.log('Post Body...' + JSON.stringify(postBody));

    const url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url);
    const localHeader =  this.headers.append('X-Exme-Operation', 'getCountExchangeOptionsForUserCollection').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader);
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
    /*let url = environment.apiUrlExme + '_table/vCountExchangeOptions?related=vAgentActiveGroups_by_agent_id&filter=(((orig_user_collection_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20';
        url = url + '(((target_id%3D'+targetAgentId1+')%20and%20(target_user_col_id%3D'+targetCollectionId1+'))or((target_id%3D'+targetAgentId2+')%20and%20(target_user_col_id%3D'+targetCollectionId2+'))or((target_id%3D'+targetAgentId3+')%20and%20(target_user_col_id%3D'+targetCollectionId3+'))or((target_id%3D'+targetAgentId4+')%20and%20(target_user_col_id%3D'+targetCollectionId4+')))))&order=agent_number_receives%20desc';

        console.log('Requesting Get for URL:' + url);
        return this.http.get(url, { headers: this.headers }).pipe(
          map(this.extractData),
          catchError(this.handleError)
        );

    //      .map((response: Response) => response.json())
      }
    */
     }

/*
  getCountExchangeOptionsSimple(agentUserColId: string , agentId: string ) {


//    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20((agent_number_gives%3E%200)%20or%20(agent_number_receives%3E%200)))';
//    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+'))';
    let url = environment.apiUrlExme + '_table/vCountExchangeOptionsSimple?filter=(((orig_user_collection_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')))&order=score%20desc';

    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
//      .map((response: Response) => response.json())
  }
*/

  getCountExchangeOptionsSimpleWGroupList(agentUserColId: string , agentId: string ) {

//    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20((agent_number_gives%3E%200)%20or%20(agent_number_receives%3E%200)))';
//    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+'))';
/*    let url = environment.apiUrlExme + '_table/vCountExchangeOptionsSimple?related=vAgentActiveGroups_by_agent_id&filter=(((orig_user_collection_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')))&order=score%20desc';

    console.log("Requesting Get for URL:" + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/
//      .map((response: Response) => response.json())

    const postBody ={agentUserColId,agentId};
    return this.localMakeRequest('getCountExchangeOptionsSimpleWGroupList',postBody,'ExMESecure','getCountExchangeOptionsSimpleWGroupList',agentId,false);

/*console.log('Post Body...' + JSON.stringify(postBody));

let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
console.log('Posting to URL: ' + url)
let localHeader =  this.headers.append('X-Exme-Operation', 'getCountExchangeOptionsSimpleWGroupList').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
console.log('Headers');
console.log(localHeader)
return this.http.post(url, postBody, {headers: localHeader}).pipe(
  map(this.extractData),
  catchError(this.handleError)
);
*/
}

 getFilteredTradeOptionsSimple(agentUserColId: string , agentId: string, collectionId: string , valueToSearch: string ) {

  //    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')%20and%20((agent_number_gives%3E%200)%20or%20(agent_number_receives%3E%200)))';
  //    let url = environment.apiUrlExme + '_table/vCountExchangeOptions?filter=((agent_user_col_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+'))';
  /*    let url = environment.apiUrlExme + '_table/vCountExchangeOptionsSimple?related=vAgentActiveGroups_by_agent_id&filter=(((orig_user_collection_id%3D' + agentUserColId + ')%20and%20(agent_id%3D'+agentId+')))&order=score%20desc';

      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );*/
  //      .map((response: Response) => response.json())

      const postBody ={agentUserColId,agentId,valueToSearch,collectionId};
      return this.localMakeRequest('getFilteredTradeOptionsSimple',postBody,'ExMESecure','getFilteredTradeOptionsSimple',agentId,false);

  /*console.log('Post Body...' + JSON.stringify(postBody));

  let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
  console.log('Posting to URL: ' + url)
  let localHeader =  this.headers.append('X-Exme-Operation', 'getCountExchangeOptionsSimpleWGroupList').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
  console.log('Headers');
  console.log(localHeader)
  return this.http.post(url, postBody, {headers: localHeader}).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );
  */
  }


/*
  getFriendCollections(agentId: string, collectionId: string) {

    let url = environment.apiUrlExme +
      '_table/vUserFriendCollectionsLst?filter=(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')&order=percent_complete%20desc';

      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
//      .map((response: Response) => response.json())
  }
*/
  getFriendCollectionsSimple(agentId: string, collectionId: string) {

    const postBody ={ theCollectionId : collectionId, theAgentId:agentId};
    return this.localMakeRequest('getFriendCollectionsSimple',postBody,'ExMESecure','getFriendCollectionsSimple',agentId,false);

    /*

    let url = environment.apiUrlExme +
      '_table/vUserFriendCollectionsLstSimple?related=vAgentActiveGroups_by_agent_id,CRM_AGENT_by_friend_agent_id&filter=(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')&order=percent_complete%20desc';

      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//      .map((response: Response) => response.json())
  }
  getCollectionStatus1Way(userCollectionId: string) {
    const postBody ={ theUserCollectionId : [ userCollectionId]};
    return this.localMakeRequest('getCollectionStatus1Way',postBody,'ExMEPublic','getStickersPerUserCol',null,false);
/*    let url = environment.apiUrlExme +
      '_table/vCntStickersPerCol?filter=user_collection_id%3D' + userCollectionId ;
      console.log('Requesting Get for URL:' + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//      .map((response: Response) => response.json())
  }
  getCollectionStatus4Way(userCollectionId1: string,userCollectionId2: string,userCollectionId3: string,userCollectionId4: string) {

    const postBody ={ theUserCollectionId : [ userCollectionId1,userCollectionId2,userCollectionId3,userCollectionId4]};
    return this.localMakeRequest('getCollectionStatus4Way',postBody,'ExMEPublic','getStickersPerUserCol',null,false);

/*
    let url = environment.apiUrlExme +
      '_table/vCntStickersPerCol?filter=(' + '(user_collection_id%3D' + userCollectionId1 +')'+'or'+'(user_collection_id%3D' + userCollectionId2 +')'+'or'+'(user_collection_id%3D' + userCollectionId3 +')'+'or'+'(user_collection_id%3D' + userCollectionId4 +')'+')';
      console.log('Requesting Get for URL:' + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//      .map((response: Response) => response.json())
  }
  getCollectionStatus16Way(
        userCollectionId1: string,userCollectionId2: string,userCollectionId3: string,userCollectionId4: string,
        userCollectionId5: string,userCollectionId6: string,userCollectionId7: string,userCollectionId8: string,
        userCollectionId9: string,userCollectionId10: string,userCollectionId11: string,userCollectionId12: string,
        userCollectionId13: string,userCollectionId14: string,userCollectionId15: string,userCollectionId16: string
    ) {
      const postBody ={ theUserCollectionId : [ userCollectionId1,userCollectionId2,userCollectionId3,userCollectionId4,
                                                userCollectionId5,userCollectionId6,userCollectionId7,userCollectionId8,
                                                userCollectionId9,userCollectionId10,userCollectionId11,userCollectionId12,
                                                userCollectionId13,userCollectionId14,userCollectionId15,userCollectionId16]};
      return this.localMakeRequest('getCollectionStatus16Way',postBody,'ExMEPublic','getStickersPerUserCol',null,false);
  /*      let postBody ={"theGroupId":groupId,"theCollectionId" : collectionId, "TheAgentId":agentId};
      return this.localMakeRequest('getFriendCollectionsForGroup',postBody,'ExMESecure','getFriendCollectionsForGroup',agentId,false);

      https://lab.exme.club/api/v2/ExMEdb/_table/vCntStickersPerCol?ids=301%2C302&id_field=user_collection_id

*/
/*
    let url = environment.apiUrlExme +
      '_table/vCntStickersPerCol?filter=(' + '(user_collection_id%3D' + userCollectionId1 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId2 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId3 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId4 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId5 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId6 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId7 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId8 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId9 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId10 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId11 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId12 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId13 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId14 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId15 +')'+'or'
                                           +'(user_collection_id%3D' + userCollectionId16 +')'
          +')';
      console.log('Requesting Get for URL:' + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//      .map((response: Response) => response.json())
  }
  getFriendCollectionsForGroup(agentId: string, collectionId: string, groupId: string) {

    const postBody ={theGroupId:groupId,theCollectionId : collectionId, theAgentId:agentId};
    return this.localMakeRequest('getFriendCollectionsForGroup',postBody,'ExMESecure','getFriendCollectionsForGroup',agentId,false);

    /*

    let url = environment.apiUrlExme +
      '_table/vUserFriendCollectionsLstForGroup?filter=(group_id%3D' + groupId +
      ')%20and%20(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')&order=percent_complete%20desc';

      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//      .map((response: Response) => response.json())
  }


  getTradingOportunitiesG(userCollectionId: string, targetCollectionId: string , agentId: string) {

    const postBody ={userCollectionId,targetCollectionId, agentId};
    return this.localMakeRequest('getTradingOportunitiesG',postBody,'ExMESecure','getTradingOportunitiesG',agentId,false);

    /*

    let url = environment.apiUrlExme +
      '_table/vTradingOportunitiesG?filter=(user_collection_id%3D' + userCollectionId +
      ')%20and%20(target_collection_id%3D' + targetCollectionId + ')%20and%20(agent_id%3D' + agent_id + ')&order=order_number';
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
  }

  getTradingOportunitiesR(userCollectionId: string, targetCollectionId: string, agentId: string) {

    const postBody ={userCollectionId,targetCollectionId, agentId};
    return this.localMakeRequest('getTradingOportunitiesR',postBody,'ExMESecure','getTradingOportunitiesR',agentId,false);

    /*

    let url = environment.apiUrlExme +
      '_table/vTradingOportunitiesR?filter=(user_collection_id%3D' + userCollectionId +
      ')%20and%20(target_collection_id%3D' + targetCollectionId + ')%20and%20(agent_id%3D' + agent_id + ')&order=order_number';
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
  }

  getSimpleTradingOportunitiesG(userCollectionId: string, targetCollectionId: string , agentId: string) {
/*
    let url = environment.apiUrlExme +
      '_table/vTradingOportunitiesG?fields=type%2Citem_id&filter=(orig_user_collection_id%3D' + userCollectionId +
      ')%20and%20(target_collection_id%3D' + targetCollectionId + ')%20and%20(agent_id%3D' + agent_id + ')';
      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );*/
//        .map((response: Response) => response.json())

    const postBody ={userCollectionId,targetCollectionId, agentId};
    return this.localMakeRequest('getSimpleTradingOportunitiesG',postBody,'ExMESecure','getSimpleTradingOportunitiesG',agentId,false);

    /*
    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'getSimpleTradingOportunitiesG').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  getSimpleTradingOportunitiesR(userCollectionId: string, targetCollectionId: string, agentId: string) {

/*    let url = environment.apiUrlExme +
      '_table/vTradingOportunitiesR?fields=type%2Citem_id&filter=(orig_user_collection_id%3D' + userCollectionId +
      ')%20and%20(target_collection_id%3D' + targetCollectionId + ')%20and%20(agent_id%3D' + agent_id + ')';
      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );*/
//        .map((response: Response) => response.json())
    const postBody ={userCollectionId,targetCollectionId, agentId};
    return this.localMakeRequest('getSimpleTradingOportunitiesR',postBody,'ExMESecure','getSimpleTradingOportunitiesR',agentId,false);

/*    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'getSimpleTradingOportunitiesR').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  getTradeRequests(agentId: string, collectionId: string, user_collection_id:  string) {

    const body = {theAgentId: agentId , theCollectionId: collectionId, theUserCollectionId: user_collection_id };

    return this.localMakeRequest('getTradeRequests',body,'ExMESecure','getTradeRequests',agentId,false);
/*

    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTrades?filter=(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')%20and%20(user_collection_id%3D' + user_collection_id + ')&order=creation_time%20DESC';
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
  }

  getTradeRequest(agentId: string, tradeRequestId: string) {

    const body = {theAgentId: agentId , tradeRequestId };

    return this.localMakeRequest('getTradeRequest',body,'ExMESecure','getTradeRequest',agentId,false);
/*

    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTrades?filter=(agent_id%3D' + agentId +')%20and%20(trade_request_id%3D' + id + ')';
      console.log("Requesting Get for URL:" + url);
    console.log("Requesting:")
    console.log(url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
  }
  getTradeRequestsWithDetailsSentById(theAgentId: string, theTradeRequestId: string, theUserCollectionId: string, theCollectionId: string) {

    const body = {theAgentId, theTradeRequestId , theUserCollectionId , theCollectionId};

    return this.localMakeRequest('getTradeRequestsWithDetailsSentById',body,'ExMESecure','getTradeRequestsWithDetailsSentById',theAgentId,false);
/*

    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTrades?filter=(agent_id%3D' + agentId +')%20and%20(trade_request_id%3D' + id + ')';
      console.log("Requesting Get for URL:" + url);
    console.log("Requesting:")
    console.log(url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
      }
//        .map((response: Response) => response.json())

  getTradeRequestsWithDetailsReceivedById(theAgentId: string, theTradeRequestId: string, theUserCollectionId: string, theCollectionId: string) {

    const body = {theAgentId, theTradeRequestId , theUserCollectionId , theCollectionId};


    return this.localMakeRequest('getTradeRequestsWithDetailsReceivedById',body,'ExMESecure','getTradeRequestsWithDetailsReceivedById',theAgentId,false);
/*

    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTrades?filter=(agent_id%3D' + agentId +')%20and%20(trade_request_id%3D' + id + ')';
      console.log("Requesting Get for URL:" + url);
    console.log("Requesting:")
    console.log(url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
  }

  getRequestDetails_a(tradeRequestId: string, agentId: string ) {

    const body = {theAgentId: agentId , theTradeRequestId: tradeRequestId };

    return this.localMakeRequest('getRequestDetails_a',body,'ExMESecure','getRequestDetails_a',agentId,false);

/*

    let url = environment.apiUrlExme +
      '_table/vTradeRequestDetails2_a?filter=(trade_request_id%3D' + tradeRequestId + ')%20and%20(agent_id%3D'+agent_id+')';

    console.log("Sending http get: " + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())


  }

  getRequestDetails_b(tradeRequestId: string, agentId: string ) {

    const body = {theAgentId: agentId , theTradeRequestId: tradeRequestId };

    return this.localMakeRequest('getRequestDetails_b',body,'ExMESecure','getRequestDetails_b',agentId,false);

/*

    let url = environment.apiUrlExme +
      '_table/vTradeRequestDetails2_b?filter=(trade_request_id%3D' + tradeRequestId + ')%20and%20(agent_id%3D'+agent_id+')';

    console.log("Sending http get: " + url);
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())


  }

  getTradeRequestsWithDetails(agentId: string, collectionId: string, userCollectionId: string) {

    const body = {theAgentId: agentId , theCollectionId: collectionId , theUserCollectionId : userCollectionId};

    return this.localMakeRequest('getTradeRequestsWithDetails',body,'ExMESecure','getTradeRequestsWithDetails',agentId,false);
/*

    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTrades?related=ReqItemsDetailsAll&filter=(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')%20and%20(user_collection_id%3D' + userCollectionId + ')&order=creation_time%20DESC';
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

  }
  getTradeRequestsWithDetailsReceived(agentId: string, collectionId: string, userCollectionId: string) {

    const body = {theAgentId: agentId , theCollectionId: collectionId , theUserCollectionId : userCollectionId};

    return this.localMakeRequest('getTradeRequestsWithDetailsReceived',body,'ExMESecure','getTradeRequestsWithDetailsReceived',agentId,false);
/*
    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTradesReceived?related=ReqItemsDetailsAll,OtherCols&filter=(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')%20and%20(user_collection_id%3D' + userCollectionId + ')&order=creation_time%20DESC';
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers })
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

  }
  getTradeRequestsWithDetailsSent(agentId: string, collectionId: string, userCollectionId: string) {

    const body = {theAgentId: agentId , theCollectionId: collectionId , theUserCollectionId : userCollectionId};

    return this.localMakeRequest('getTradeRequestsWithDetailsSent',body,'ExMESecure','getTradeRequestsWithDetailsSent',agentId,false);
/*
    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTradesSent?related=ReqItemsDetailsAll,OtherCols&filter=(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')%20and%20(user_collection_id%3D' + userCollectionId + ')&order=creation_time%20DESC';
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

  }

  getTradeRequestsWithDetailsReceivedHist(agentId: string, collectionId: string, userCollectionId: string) {

    const body = {theAgentId: agentId , theCollectionId: collectionId , theUserCollectionId : userCollectionId};

    return this.localMakeRequest('getTradeRequestsWithDetailsReceivedHist',body,'ExMESecure','getTradeRequestsWithDetailsReceivedHist',agentId,false);

/*
    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTradesReceivedHist?related=ReqItemsDetailsAll,OtherCols&filter=(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')%20and%20(user_collection_id%3D' + userCollectionId + ')&order=creation_time%20DESC';
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers })
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

  }
  getTradeRequestsWithDetailsSentHist(agentId: string, collectionId: string, userCollectionId: string) {

    const body = {theAgentId: agentId , theCollectionId: collectionId , theUserCollectionId : userCollectionId};

    return this.localMakeRequest('getTradeRequestsWithDetailsSentHist',body,'ExMESecure','getTradeRequestsWithDetailsSentHist',agentId,false);
/*
    let url = environment.apiUrlExme +
      '_table/vUserTradeRequests_SplitTradesSentHist?related=ReqItemsDetailsAll,OtherCols&filter=(agent_id%3D' + agentId +
      ')%20and%20(collection_id%3D' + collectionId + ')%20and%20(user_collection_id%3D' + userCollectionId + ')&order=creation_time%20DESC';
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

  }
/*
  getTradeRequestsWithDetHist(agentId: string, collectionId: string, userCollectionId:string) {

    let body = {"theCollectionId": collectionId , "theUserCollectionId" : userCollectionId};

    return this.localMakeRequest('getTradeRequestsWithDetHist',body,'ExMESecure','getTradeRequestsWithDetHist',agentId,false);

--    let url = environment.apiUrlExme +
--      '_table/vUserTradeRequests_SplitTrades?related=ReqItemsDetailsAll,OtherCols&filter=(agent_id%3D' + agentId +
--      ')%20and%20(collection_id%3D' + collectionId + ')%20and%20(user_collection_id%3D' + userCollectionId + ')&order=creation_time%20DESC';
--      console.log("Requesting Get for URL:" + url);

--      return this.http.get(url, { headers: this.headers }).pipe(
--        map(this.extractData),
--        catchError(this.handleError)
--      );

//        .map((response: Response) => response.json())

  }

*/
  addItemService(userCollectionId: string, itemId: string, body: any, agent_id: string) {
/*
    let url = environment.apiUrlExme +
      '_table/COL_CD_USER_COL_DETAILS?filter=(user_collection_id%3D' + userCollectionId +
      ')%20and%20(item_id%3D' + itemId + ')';
    console.log("Calling patch for:" + url)
    console.log("Headers");
    console.log(this.headers);
    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/
//      .map((response: Response) => response.json())
    const postBody ={userCollectionId,itemId, resource: body};
    return this.localMakeRequest('addItemService',postBody,'ExMESecure','addItemService',agent_id,false);
/*
    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'addItemService').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  addNewItemService(insertItem: any, agent_id: string) {

    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const formattedDate = date+' '+time;

    const insertBody = {resource: [{user_collection_id: insertItem.user_collection_id,item_id: insertItem.item_id,quantity: 1,creation_time: formattedDate,modification_time: formattedDate,
          agent_id,collection_id: insertItem.collection_id,num_reserved: 0,num_requests_sent: 0}]};

    return this.localMakeRequest('addNewItemService',insertBody,'ExMESecure','addNewItemService',agent_id,false);
/*    console.log('Insert Body...' + JSON.stringify(insertBody));

    let url = environment.apiUrlExme + '_table/COL_CD_USER_COL_DETAILS';

    return this.http.post(url, insertBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())

  }

  insertNewTradeRequest(insertItem: any, dateTime: string) {
    const insertBody = {resource: [{source_agent_id:insertItem.agent_id,target_agent_id:insertItem.target_id,collection_id:insertItem.collection_id,source_user_col_id:insertItem.agent_user_col_id,
                                    target_user_col_id:insertItem.target_user_col_id,source_agent_status:'created',target_agent_status:'created',request_status:'created',version_number: 0,creation_time: dateTime,
                                    modification_time: dateTime}]};
    return this.localMakeRequest('insertNewTradeRequest',insertBody,'ExMESecure','insertNewTradeRequest',null,false);
/*
    let url = environment.apiUrlExme + '_table/COL_CD_USER_TRADE_REQUEST';
    console.log("posting object" );
    console.log(insertBody);
    console.log("to URL" + url);

    return this.http.post(url, insertBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
    //      .map((response: Response) => response.json())

  }

  insertTradeRequestItems(insertBody: any) {
    return this.localMakeRequest('insertTradeRequestItems',insertBody,'ExMEPublic','insertTradeRequestItems',null,false);
/*
    let url = environment.apiUrlExme + '_table/COL_CD_USER_TRADE_DETAILS';


    return this.http.post(url, insertBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())

  }

  insertUserCollection(collectionId: string, agentId: string, collectionTag: string) {

    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const formattedDate = date+' '+time;
    const insertBody = {resource: [ {agent_id: agentId, collection_id: collectionId, collection_tag: collectionTag, creation_time: formattedDate, modification_time: formattedDate, collection_type: 'O'}]};
    return this.localMakeRequest('insertUserCollection',insertBody,'ExMESecure','insertUserCollection',agentId,false);

/*
    let url = environment.apiUrlExme + '_table/COL_CD_USER_COLLECTIONS';
    console.log("Sending Post to " + url);
    console.log("Post body is:");
    console.log(insertBody);

    return this.http.post(url, insertBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
//      .map((response: Response) => response.json())
*/
/*
    console.log('Post Body...' + JSON.stringify(insertBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'insertUserCollection').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, insertBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }


  insertDelegateUserCollectionRequest(userCollectionId: string, agentId: string,email: string, message: string) {

    //console.log('entered insertDelegateUserCollectionRequest');
    //var today = new Date();
    //var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    //var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    //var formattedDate = date+' '+time;

    const insertBody = { resource: [{user_collection_id: userCollectionId,owner_agent_id: agentId,email,status: 0}]};
    return this.localMakeRequest('insertDelegateUserCollectionRequest',insertBody,'ExMESecure','insertDelegateUserCollectionRequest',agentId,false);


/*
      console.log('about to send http request');

    let url = environment.apiUrlExme + '_table/CRM_USER_COLS_DELEG_PENDING';
    console.log(url);
    console.log(insertBody);
    console.log(this.headers);

    var returnHttp = this.http.post(url, insertBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
//      .map((response: Response) => response.json());

      console.log('about to exit insertDelegateUserCollectionRequest');

    return returnHttp;
*/

  }

  updateOrigUserColId(userCollectionId: string, agentId: string) {
    const body = {  agentId , userCollectionId , resource: [{ orig_user_collection_id:userCollectionId}]};
    return this.localMakeRequest('updateOrigUserColId',body,'ExMESecure','updateOrigUserColId',agentId,false);

    /*
    let url = environment.apiUrlExme +
      '_table/COL_CD_USER_COLLECTIONS?filter=user_collection_id%3D' + userCollectionId;

    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  updateCollectionIdTradingStatus(userCollectionId: string, status: number, agentId: string) {
    const body = {  agentId , userCollectionId ,  resource:[{trading_availability_status:status}]};
    return this.localMakeRequest('updateCollectionIdTradingStatus',body,'ExMESecure','updateCollectionIdTradingStatus',agentId,false);

/*
    console.log(body)
    console.log("setting collection_id" + userCollectionId + " to " + status )
    let url = environment.apiUrlExme +
      '_table/COL_CD_USER_COLLECTIONS?filter=user_collection_id%3D' + userCollectionId;

    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  deactivateUserColId(userCollectionId: string, agentId: string ) {

    const body = {  agentId , userCollectionId , resource: [{active:0 }]};
    return this.localMakeRequest('deactivateUserColId',body,'ExMESecure','deactivateUserColId',agentId,false);
/*
    let url = environment.apiUrlExme +
      '_table/COL_CD_USER_COLLECTIONS?filter=user_collection_id%3D' + userCollectionId;
    console.log("sending request to " + url);

    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
    //      .map((response: Response) => response.json())

  }

  acceptDelegationRequest(delegation_id: number,tag: string, agent_id: string ) {

    const paramsBody = { agent_id, params: [{name: 'p_delegation_id',value:delegation_id},{name: 'p_tag',value:tag},{name: 'p_agent_id',value:agent_id},{name: 'p_user_collection_id',value:'@p_user_collection_id'},
        {name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('acceptDelegationRequest',paramsBody,'ExMESecure','acceptDelegationRequest',agent_id,false);
/*
    let url = environment.apiUrlExme + '_proc/COL_ACCEPT_DELEG_REQ';
    return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json());
  }

  fillUserCollection(newUserCollectionId: number, agentId: string){

    const paramsBody = {agent_id: agentId, params: [{name: 'p_user_collection_id',value:newUserCollectionId},{name: 'p_agent_id',value:agentId},{name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('fillUserCollection',paramsBody,'ExMESecure','fillUserCollection',agentId,false);
/*
    let url = environment.apiUrlExme + '_proc/COL_FILL_COLLECTION';
    return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }


  rejectDelegationRequest(delegation_id: number, agentId: string) {

    const paramsBody = {agent_id: agentId ,params: [{name: 'p_delegation_id',value: delegation_id},{name: 'p_agent_id',value:agentId},{name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('rejectDelegationRequest',paramsBody,'ExMESecure','rejectDelegationRequest',agentId,false);
/*
    console.log(paramsBody);
    let url = environment.apiUrlExme + '_proc/COL_REFUSE_DELEG_REQ';
    return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())

  }
  cancelDelegationRequest(delegation_id: number, agent_id: string) {

    const paramsBody = {agent_id ,params: [{name: 'p_delegation_id',value: delegation_id},{name: 'p_agent_id',value:agent_id},{name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('cancelDelegationRequest',paramsBody,'ExMESecure','cancelDelegationRequest',agent_id,false);
/*
    console.log(paramsBody);
    let url = environment.apiUrlExme + '_proc/COL_CANCEL_DELEG_REQ';
    return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())

  }
  callProcSendRequest(pendingTradeRequestId: string, message: string, agentId: string) {

    const paramsBody = {agent_id: agentId , params: [{name: 'p_trade_request_id',value:pendingTradeRequestId},{name: 'p_agent_id',value:agentId},{name: 'p_message',value:message},{name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('callProcSendRequest',paramsBody,'ExMESecure','callProcSendRequest',agentId,false);

/*
    let url = environment.apiUrlExme + '_proc/COL_PROC_SEND_TRADE_REQ_2';
    return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
//      .map((response: Response) => response.json())

  }

  callProcessTradeRequest(tradeRequestId: string, message: string, action: string ,agent_id: string) {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const formattedDate = date+' '+time;
    const paramsBody = { agent_id , params: [{name: 'p_trade_request_id',value:tradeRequestId},{name: 'p_agent_id',value:agent_id},{name: 'p_message',value:message},{name: 'p_date',value:formattedDate},{name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};

    if (action === 'accept')
      { //this.url = environment.apiUrlExme + '_proc/COL_PROC_ACCEPT_TRADE_REQ_PART2'
        return this.localMakeRequest('callProcessTradeRequest',paramsBody,'ExMESecure','callProcessTradeRequestA',agent_id,false);
    }
    else if (action === 'cancel')
      { //this.url = environment.apiUrlExme + '_proc/COL_PROC_CANCEL_TRADE_REQ_PART2'
        return this.localMakeRequest('callProcessTradeRequest',paramsBody,'ExMESecure','callProcessTradeRequestC',agent_id,false);
    }
    else if (action === 'close')
      { //this.url = environment.apiUrlExme + '_proc/COL_PROC_CLOSE_TRADE_REQ_PART2'
        return this.localMakeRequest('callProcessTradeRequest',paramsBody,'ExMESecure','callProcessTradeRequestCl',agent_id,false);
    }
    else {
      return null;
    }
/*
      console.log("URL:");
      console.log(this.url);
      console.log(paramsBody);
      console.log(JSON.stringify(paramsBody));
    return this.http.post(this.url, paramsBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())

  }

  callAddMultipleItems(collectionId: string, itemsList: string, agentId: string) {

    const paramsBody = { params: [{name: 'p_user_col_id',value:collectionId},{name: 'p_agent_id',value:agentId},{name: 'p_item_list',value:itemsList},{name: 'p_delimiter',value:','},{name: 'p_force',value:1},
        {name: 'p_validate',value:0},{name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('callAddMultipleItems',paramsBody,'ExMESecure','callAddMultipleItems',agentId,false);
/*
    let url = environment.apiUrlExme + '_proc/COL_REGISTER_MULTIPLE_ITEMS';
    return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
//      .map((response: Response) => response.json())

  }

  updateColTag(userCollectionId: string, colTag: string, agentId: string) {
    const body = {  userCollectionId , agentId , resource: [{collection_tag:colTag}]};
    return this.localMakeRequest('updateColTag',body,'ExMESecure','updateColTag',agentId,false);
/*
    let url = environment.apiUrlExme +
      '_table/COL_CD_USER_COLLECTIONS?filter=user_collection_id%3D' + userCollectionId;

    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
//      .map((response: Response) => response.json())
  }

  setTradeRequestRating(trade_request_id: string,type: string,rated_value: number, agentId: string) {
    let body = {};
    if (type === 'Sent') {
      body ={ tradeRequestId: trade_request_id, agentId, resource:[{source_to_tgt_trade_rating:rated_value}]};
      return this.localMakeRequest('setTradeRequestRating',body,'ExMESecure','setTradeRequestRatingS',agentId,false);
    }
    else {
      body ={ tradeRequestId: trade_request_id, agentId, resource:[{tgt_to_source_trade_rating:rated_value}]};
      return this.localMakeRequest('setTradeRequestRating',body,'ExMESecure','',agentId,false);
    }
/*
    let url = environment.apiUrlExme +
      '_table/COL_CD_USER_TRADE_REQUEST?filter=trade_request_id%3D' + trade_request_id;

    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  */
//      .map((response: Response) => response.json())

  }

  recalculateTradeRating(trade_partner_id){

    const paramsBody = {params: [{name: 'p_agent_id',value:trade_partner_id},{name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('recalculateTradeRating',paramsBody,'ExMEPublic','recalculateTradeRating',null,false);
/*
    let url = environment.apiUrlExme + '_proc/RECALCULATE_AGENT_TRADE_RATING';
    return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
//      .map((response: Response) => response.json())

  }

  /*
  getColListSimple(agentId) {
    const url = environment.apiUrlExme + `_table/COL_CD_USER_COLLECTIONS?related=COL_RD_COLLECTIONS_by_collection_id&filter=(agent_id%3D${agentId})and(active%3D1)`;
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
//    .map((response: Response) => response.json())
  }
  */

  getUserCollection(uci: string, agentId: string) {
    const body = { userCollectionId: uci , agentId};
    return this.localMakeRequest('getUserCollection',body,'ExMESecure','getUserCollection',agentId,false);

/*
    let url = environment.apiUrlExme + `_table/vUserCollections?filter=(user_collection_id%3D${uci})and(agent_id%3D${agentId})`;
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//    .map((response: Response) => response.json())
  }

  getUserColExternalId(uci: string, agentId: string) {
    const body = { userCollectionId: uci , agentId};
    return this.localMakeRequest('getUserColExternalId',body,'ExMESecure','getUserColExternalId',agentId,false);
/*
    let url = environment.apiUrlExme + `_table/COL_CD_USER_COLLECTIONS/${uci}?id_field=user_collection_id&fields=external_id`;
    console.log('-------------------------------')
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//    .map((response: Response) => response.json())
  }

  // MIGRATED TO SECURE
  getTradingOtherColsR(user_collection_id: string, target_agent: string, agent_id: string) {
    const body = { userCollectionId: user_collection_id, targetAgent: target_agent , agentId : agent_id };
    return this.localMakeRequest('getTradingOtherColsR',body,'ExMESecure','getTradingOtherColsR',agent_id,false);
/*
    let url = environment.apiUrlExme +
      `_table/vTradingOportunitiesR?filter=(user_collection_id!=${user_collection_id})and(target_agent=${target_agent})and(agent_id=${agent_id})and(source_active=true)and(target_active=true)and(type=Receive)&order=order_number`;
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );*/
//        .map((response: Response) => response.json())
/*    let postBody ={"userCollectionId":user_collection_id,"targetAgent":target_agent,"agentId":agent_id};

    console.log('Post Body...' + JSON.stringify(postBody));

    let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
    console.log('Posting to URL: ' + url)
    let localHeader =  this.headers.append('X-Exme-Operation', 'getTradingOtherColsR').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader)
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  getTradingOtherColsDistinctR(user_collection_id: string, target_agent: string, agent_id: string) {
    const body = { userCollectionId: user_collection_id, targetAgent: target_agent , agentId : agent_id };
    return this.localMakeRequest('getTradingOtherColsDistinctR',body,'ExMESecure','getTradingOtherColsDistinctR',agent_id,false);
/*
    let url = environment.apiUrlExme +
      `_table/vTradingOportunitiesR` +
      `?fields=agent_id,%20target_agent,%20user_collection_id,%20orig_user_collection_id,%20source_collection_tag` +
      `&filter=(%20orig_user_collection_id!=${user_collection_id})and(target_agent=${target_agent})and(agent_id=${agent_id})and(source_active=true)and(target_active=true)and(source_trading_availability_status=1)and(target_trading_availability_status=1)and(type=Receive)` +
      `&group=agent_id,%20target_agent,%20user_collection_id,%20orig_user_collection_id,%20source_collection_tag`;
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
        }


  getTradingOtherColsG(user_collection_id: string, target_agent: string, agent_id: string) {
    const body = { userCollectionId: user_collection_id, targetAgent: target_agent , agentId : agent_id };
    return this.localMakeRequest('getTradingOtherColsG',body,'ExMESecure','getTradingOtherColsG',agent_id,false);
/*
    let url = environment.apiUrlExme +
      `_table/vTradingOportunitiesG?filter=(user_collection_id!=${user_collection_id})and(target_agent=${target_agent})and(agent_id=${agent_id})and(source_active=true)and(target_active=true)and(type=Give)&order=order_number`;

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );*/
//        .map((response: Response) => response.json())
/*      let postBody ={"userCollectionId":user_collection_id,"targetAgent":target_agent,"agentId":agent_id};

      console.log('Post Body...' + JSON.stringify(postBody));

      let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
      console.log('Posting to URL: ' + url)
      let localHeader =  this.headers.append('X-Exme-Operation', 'getTradingOtherColsG').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      console.log('Headers');
      console.log(localHeader)
      return this.http.post(url, postBody, {headers: localHeader}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  */
        }

  getTradingOtherColsDistinctG(user_collection_id: string, target_agent: string, agent_id: string) {
    const body = { userCollectionId: user_collection_id, targetAgent: target_agent , agentId : agent_id };
    return this.localMakeRequest('getTradingOtherColsDistinctG',body,'ExMESecure','getTradingOtherColsDistinctG',agent_id,false);
/*
    let url = environment.apiUrlExme +
      `_table/vTradingOportunitiesG` +
      `?fields=agent_id,%20target_agent,%20user_collection_id,%20orig_user_collection_id,%20source_collection_tag` +
      `&filter=(%20orig_user_collection_id!=${user_collection_id})and(target_agent=${target_agent})and(agent_id=${agent_id})and(source_active=true)and(target_active=true)and(source_trading_availability_status=1)and(target_trading_availability_status=1)and(type=Give)` +
      `&group=agent_id,%20target_agent,%20user_collection_id,%20orig_user_collection_id,%20source_collection_tag`;
      console.log("Requesting Get for URL:" + url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
 */
    }


  updateMasterTradeReq(tradeRequestId: string) {
    const body = { tradeRequestId, resource:[{master_request_id:tradeRequestId}]};

    return this.localMakeRequest('updateMasterTradeReq',body,'ExMESecure','updateMasterTradeReq',null,false);
/*
    let url = environment.apiUrlExme +
      '_table/COL_CD_USER_TRADE_REQUEST?filter=trade_request_id%3D' + tradeRequestId;

    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
    //      .map((response: Response) => response.json())
  }


  insertOtherColTradeRequest(insertBody: any) {


    return this.localMakeRequest('insertOtherColTradeRequest',insertBody,'ExMESecure','insertOtherColTradeRequest',null,false);

/*
    let url = environment.apiUrlExme + '_table/COL_CD_USER_TRADE_REQUEST';

    return this.http.post(url, insertBody, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())

  }

  getOtherColsRequests(masterRequestId: string) {
    const body = {  masterRequestId };
    return this.localMakeRequest('getOtherColsRequests',body,'ExMEPublic','getOtherColsRequests',null,false);

/*    const url = `${environment.apiUrlExme}_table/COL_CD_USER_TRADE_REQUEST?filter=(master_request_id%3D${masterRequestId})`;
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
//      .map((response: Response) => response.json())
  }

  getOtherColsRequestsWithItems(masterRequestId: string) {
    const body = {  masterRequestId };
    return this.localMakeRequest('getOtherColsRequestsWithItems',body,'ExMEPublic','getOtherColsRequestsWithItems',null,false);
/*
    const url = `${environment.apiUrlExme}_table/vMasterRequestCols?filter=(master_request_id=${masterRequestId})&related=items_a,items_b`;
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())

  }

  DoNotShowOtherTradesColAlert(agentId: string) {
    const body = {  agentId, resource: [{ hide_other_col_trade_alert: 1 }] };
    return this.localMakeRequest('DoNotShowOtherTradesColAlert',body,'ExMESecure','DoNotShowOtherTradesColAlert',agentId,false);
/*
    const url = `${environment.apiUrlExme}_table/CRM_AGENT?filter=(AGENT_ID%3D${agentId})`;
    return this.http.patch(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
//      .map((response: Response) => response.json())
  }

  getBinders( theAgentId, theUserCollectionId, theCollectionId, request ){
      const paramsBody = {params: [ {name: 'pAgentId',value:theAgentId},
                                    {name: 'pUserCollectionId',value:theUserCollectionId},
                                    {name: 'pCollectionId',value:theCollectionId},
                                    {name: 'pRequest',value:request},
                                    {name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
      return this.localMakeRequest('getBinders',paramsBody,'ExMEFinGen','getBinders',theAgentId,false);
    }
/*
    const paramsBody = {params: [ {name: 'pAgentId',value:agentId},
                                  {name: 'userCollectionId',value:userCollectionId},
                                  {name: 'collectionId',value:collectionId},
                                  {name: 'numBinders',value:numBinders},
                                  {name: 'totalValue',value:totalValue},
                                  {name: 'msisdn',value:msisdn},
                                  {name: 'finType',value:finMethod},
                                  {name: 'invoiceRequired',value:invoiceRequired},
                                  {name: 'nifForInvoice',value:nifForInvoice},
                                  {name: 'storeNIF',value:storeNIF},
                                  {name: 'storeMsisdn',value:storeMsisdn},
                                  {name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('getBinders',paramsBody,'ExMEFinGen','getBinders',agentId,false);
  }

*/
  openBinders(theAgentId: string, theCollectionId: string, theUserCollectionId: string, num: number) {

    const paramsBody = {params: [ {name: 'p_agent_id',value:theAgentId},
                                  {name: 'p_user_collection_id',value:theUserCollectionId},
                                  {name: 'p_collection_id',value:theCollectionId},
                                  {name: 'p_number_of_binders',value:num},
                                  {name: 'p_distribute_spared',value:0},
                                  {name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};

    return this.localMakeRequest('openBinders',paramsBody,'ExMESecure','openBinders',theAgentId,false);
/*
[openBinders]
target=_table/COL_RD_COLLECTIONS
method=proc
service=ExMEdb
session_field=p_agent_id
*/
  }
  openSpinner(theAgentId: string, theCollectionId: string, theUserCollectionId: string) {

    const paramsBody = {params: [ {name: 'p_agent_id',value:theAgentId},
                                  {name: 'p_user_collection_id',value:theUserCollectionId},
                                  {name: 'p_collection_id',value:theCollectionId},
                                  {name: 'p_finished_collection',value: '@p_finished_collection'},
                                  {name: 'p_spinner_result',value: '@p_spinner_result'},
                                  {name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};

    return this.localMakeRequest('openSpinner',paramsBody,'ExMESecure','openSpinner',theAgentId,false);
/*
[openSpinner]
target=_proc/EXME_OPEN_SPINNERS
method=proc
service=ExMEdb
session_field=p_agent_id
*/
  }

  getCollectionFin(theCollectionId: string) {

    const body = {theCollectionId };
    return this.localMakeRequest('getCollectionFin',body,'ExMEPublic','getCollectionFin',null,false);

  }


  GetCollectionInfoForPhysicalRequest(theAgentId: string,theCollectionId: string, theUserCollectionId: string)
  {

    const paramsBody = {agentId: theAgentId, theCollectionId  , theUserCollectionId} ;
    return this.localMakeRequest('GetCollectionInfoForPhysicalRequest',paramsBody,'ExMESecure','GetCollectionInfoForPhysicalRequest',theAgentId,false);

  }

  getPhysicalRequest(agentId: string, collectionId: string) {

    const paramsBody = {theAgentId: agentId, theCollectionId: collectionId} ;
    return this.localMakeRequest('getPhysicalRequest',paramsBody,'ExMESecure','getPhysicalRequest',agentId,false);

  }

  getAlbumByCollectionId(collection_id) {
    const paramsBody = { theCollectionId: collection_id } ;
    return this.localMakeRequest('getAlbumDataById',paramsBody,'ExMEPublic','getAlbumDataById',null,false);
  }

  getAgentFinData(theAgentId: string) {

    const body = {theAgentId };
    return this.localMakeRequest('getAgentFinData',body,'ExMESecure','getAgentFinData',theAgentId,false);
  }

  enterUserCollection(theAgentId: string, theUserCollectionId: string) {
    const paramsBody = { agent_id: theAgentId, params: [{name: 'p_agent_id',value:theAgentId},{name: 'p_user_collection_id',value:theUserCollectionId},{name: 'p_num_updates',value:'@p_num_updates'},{name: 'p_return_code',value:'@p_return_code'},{name: 'p_return_message',value:'@p_return_message'}]};
    return this.localMakeRequest('enterUserCollection',paramsBody,'ExMESecure','enterUserCollection',theAgentId,false);
  }

  markUsedUserCollection(theAgentId: string, theUserCollectionId: string) {
    const paramsBody = { agent_id: theAgentId, params: [{name: 'p_agent_id',value:theAgentId},{name: 'p_user_collection_id',value:theUserCollectionId},{name: 'p_num_updates',value:'@p_num_updates'},{name: 'p_return_code',value:'@p_return_code'},{name: 'p_return_message',value:'@p_return_message'}]};
    return this.localMakeRequest('markUsedUserCollection',paramsBody,'ExMESecure','markUsedUserCollection',theAgentId,false);
/*
[markUsedUserCollection]
target=_proc/EXME_UPDATE_COL_POPULARITY
method=get
service=ExMEdb
sessionField=p_agent_id

*/

  }

  getCollectionType(collection_id: string) {
    const paramsBody = { theCollectionId: collection_id } ;
    return this.localMakeRequest('getCollectionType',paramsBody,'ExMEPublic','getCollectionType',null,false);
/*
[getCollectionType]
target=_table/COL_RD_COLLECTIONS
method=get
service=ExMEdb
filterField[]=collection_id##%3D##theCollectionId
fields=collection_type

*/

  }

  getCollectionById(collection_id: any) {
    const paramsBody = { theCollectionId: collection_id } ;
    return this.localMakeRequest('getCollectionById',paramsBody,'ExMEPublic','getCollectionById',null,false);

/*

[getCollectionById]
target=_table/vPublicCollections
method=get
service=ExMEdb
filterField[]=collection_id##%3D##theCollectionId
limit=20
orderField=popularity%20desc

*/

  }

  getCollectionByUuid(collection_id: any) {
    const paramsBody = { theCollectionId: collection_id } ;
    return this.localMakeRequest('getCollectionByUuid',paramsBody,'ExMEPublic','getCollectionByUuid',null,false);

/*

[getCollectionByUuid]
target=_table/vPublicCollections
method=get
service=ExMEdb
filterField[]=collection_uuid##%3D##theCollectionId
limit=1

*/

  }

  getPhysColReqCurrentStatus( theUserCollectionId, theAgentId){
    const paramsBody = { theUserCollectionId,theAgentId} ;
    return this.localMakeRequest('getPhysColReqCurrentStatus',paramsBody,'ExMESecure','getPhysColReqCurrentStatus',theAgentId,false);

/*

[getPhysColReqCurrentStatus]
target=_table/fdfgdgfdggddf
method=get
service=ExMEdb
filterField[]=collection_id##%3D##theCollectionId
limit=20
orderField=popularity%20desc

*/

  }

/*   submitPhysColRequest( theAgentId, theUserCollectionId, theCollectionId, request ){
    const paramsBody = {params: [ {name: 'pAgentId',value:theAgentId},
                                  {name: 'pUserCollectionId',value:theUserCollectionId},
                                  {name: 'pCollectionId',value:theCollectionId},
                                  {name: 'pRequest',value:request},
                                  {name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('submitPhysColRequest',paramsBody,'ExMEFinGen','submitPhysColRequest',theAgentId,false);
  } */

  submitPhysColOrder(theAgentId, theCollectionId, theUserCollectionId, request){
    const paramsBody = {params: [ {name: 'pAgentId',value:theAgentId},
                                  {name: 'pCollectionId',value:theCollectionId},
                                  {name: 'pUserCollectionId',value:theUserCollectionId},
                                  {name: 'pRequest',value:request},
                                  {name: 'p_return_code',value:'@ret'},{name: 'p_return_message',value:'@retmess'}]};
    return this.localMakeRequest('submitPhysColRequest',paramsBody,'ExMEFinGen','submitPhysColRequest',theAgentId,false);
  }


  getPhysicalColData(theAgentId, theCollectionId, theUserCollectionId){
    const paramsBody = {params: [ {name: 'pAgentId',value:theAgentId},
                                  {name: 'pUserCollectionId',value:theUserCollectionId},
                                  {name: 'pCollectionId',value:theCollectionId},
                                  {name: 'pStatus',value:'@pStatus'},
                                  {name: 'pPhysColReqId',value:'@pPhysColReqId'},
                                  {name: 'pDaysSinceLastProp',value:'@pDaysSinceLastProp'},
                                  {name: 'pMissingCards',value:'@pMissingCards'},
                                  {name: 'pReturnCode',value:'@pReturnCode'},
                                  {name: 'pReturnMessage',value:'@pReturnMessage'}
                                ]};
    return this.localMakeRequest('getPhysicalColData',paramsBody,'ExMESecure','getPhysicalColData',theAgentId,false);
  }

  setPhysColPropData(theAgentId, theCollectionId, theUserCollectionId){
    const paramsBody = {params: [ {name: 'pAgentId',value:theAgentId},
                                  {name: 'pUserCollectionId',value:theUserCollectionId},
                                  {name: 'pCollectionId',value:theCollectionId},
                                  {name: 'pReturnCode',value:'@ret'},{name: 'pReturnMessage',value:'@retmess'}]};
    return this.localMakeRequest('setPhysColPropData',paramsBody,'ExMESecure','setPhysColPropData',theAgentId,false);
  }


  localMakeRequest(callFunction: string, body: any , service: string , operation: string, agent_id: string , deleteSession: boolean) {
    console.log('Post Body called from: ' + callFunction);
    console.log(JSON.stringify(body));

    const url = environment.apiUrl+ 'api/v2/' + service;
    console.log('Posting to URL: ' + url);
    // AMPM 20230629
    //let localHeader =  this.headers.append('X-Exme-Operation', operation).set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    let localHeader = this.headerSet(this.headers,'X-Exme-Operation', operation);
    console.log(localHeader);
    localHeader = this.headerSet(localHeader,'X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log(localHeader);
    if ( deleteSession ) {
      //localHeader = localHeader.delete('X-ExME-Session-Token');
      localHeader = this.headerSet(localHeader,'X-ExME-Session-Token',null);
      console.log(localHeader);
    }
    if ( agent_id != null && localHeader.get('X-Exme-Agent-Id') != agent_id ) {
      //localHeader = localHeader.delete('X-Exme-Agent-Id').set('X-Exme-Agent-Id',agent_id);
      localHeader = this.headerSet(localHeader,'X-Exme-Agent-Id',agent_id);
      console.log(localHeader);

    }
    console.log('Headers for call from ' + callFunction + ' are:');
    console.log(localHeader);
    return this.http.post(url, body, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );

  }

  getAlbumId( theCollectionId: any) {
    const body = {  theCollectionId };
    return this.localMakeRequest('getAlbumByCollectionId',body,'ExMEPublic','getAlbumByCollectionId',null,false);
  }

  getAlbumData( albumId: any) {
    const body = {  albumId };
    return this.localMakeRequest('getAlbumData',body,'ExMEPublic','getAlbumData',null,false);
  }

  getSectionData( albumId: any, sectionId: any) {
    const paramsBody = { albumId, sectionId };
    return this.localMakeRequest('getSectionData',paramsBody,'ExMEPublic','getSectionData',null,false);
  }

  getNewColId ( theCollectionId: string, collectionOwnerId: string ) {
    const body = { theCollectionId };
    return this.localMakeRequest('getOrigNewColIdByColId',body,'ExMESecure','getOrigNewColIdByColId', collectionOwnerId, false);
  }

  notifyImpressionClick(agentId: string, advertizementCfgImpressId: string, advertizementImpressLogId: string) {
    const paramsBody = {params: [ {name: 'pAgentId',value:agentId},
                                  {name: 'pAdvertCfgImpressId',value:advertizementCfgImpressId},
                                  {name: 'pAdvertImpressLogId',value:advertizementImpressLogId},
                                  {name: 'pReturnCode',value:'@ret'},{name: 'pReturnMessage',value:'@retmess'}]};
    return this.localMakeRequest('notifyImpressionClick',paramsBody,'ExMESecure','notifyImpressionClick', agentId, false);
  }
  notifyPrizeClick(agentId: string, advertizementCfgPrizeId: string, advertizementPrizeLogId: string) {
    const paramsBody = {params: [ {name: 'pAgentId',value:agentId},
                                  {name: 'pAdvertCfgPrizeId',value:advertizementCfgPrizeId},
                                  {name: 'pAdvertPrizeLogId',value:advertizementPrizeLogId},
                                  {name: 'pReturnCode',value:'@ret'},{name: 'pReturnMessage',value:'@retmess'}]};
    return this.localMakeRequest('notifyPrizeClick',paramsBody,'ExMESecure','notifyPrizeClick', agentId, false);
  }
  getAgentColReferralCode(agentId: string, collectionId: string) {
    const paramsBody = {params: [ {name: 'pAgentId',value:agentId},
                                  {name: 'pCollectionId',value:collectionId},
                                  {name: 'pReturnCode',value:'@ret'},{name: 'pReturnMessage',value:'@retmess'}]};
    return this.localMakeRequest('getAgentColReferralCode',paramsBody,'ExMESecure','getAgentColReferralCode', agentId, false);
  }

  getUserColReferralCode(agentId: string, userCollectionId: string) {
    const paramsBody = {params: [ {name: 'pAgentId',value:agentId},
                                  {name: 'pUserCollectionId',value:userCollectionId},
                                  {name: 'pReturnCode',value:'@ret'},{name: 'pReturnMessage',value:'@retmess'}]};
    return this.localMakeRequest('getUserColReferralCode',paramsBody,'ExMESecure','getUserColReferralCode', agentId, false);
  }

  validateAgentColReferralCode(agentId: string, collectionId: string, refCode: string, store: boolean) {
    const paramsBody = {params: [ {name: 'pAgentId',value:agentId},
                                  {name: 'pCollectionId',value:collectionId},
                                  {name: 'pRefCode',value:refCode},
                                  {name: 'pStoreCode',value:(store?1:0)},
                                  {name: 'pReturnCode',value:'@ret'},{name: 'pReturnMessage',value:'@retmess'}]};
    return this.localMakeRequest('validateAgentColReferralCode',paramsBody,'ExMESecure','validateAgentColReferralCode', agentId, false);
  }

  startPickupProcess(phy_req_id: string, agent_id: string, collection_id: string){
    const paramsBody = {params: [ {name: 'pPhyReqId', value:phy_req_id},
                                  {name: 'pAgentId',value:agent_id},
                                  {name: 'pCollectionId',value:collection_id},
                                  {name: 'pOperationCode', value: 0},
                                  {name: 'pCode',value: '000000'},
                                  {name: 'pReturnCode',value:'@ret'},
                                  {name: 'pReturnMessage',value:'@retmess'}]};
    return this.localMakeRequest('pickupAlbumUser',paramsBody,'ExMESecure','pickupAlbumUser', agent_id, false);
  }

  private headerSet(sourceHeader: HttpHeaders, changeKey: string, changeValue: any){
    let retHeader=new HttpHeaders();
    sourceHeader.keys().forEach( (key) => {
      if ( key !== changeKey){
        retHeader=retHeader.append(key,sourceHeader.get(key));
      } else {
      }
    });
    if ( changeValue !== undefined && changeValue !== null && changeValue !== '') {
      retHeader=retHeader.append(changeKey,changeValue);
//      console.log('Appending Key');
    }
//    console.log('Returning:');
//    console.log(retHeader);
    return retHeader;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(error) ;
//        `Backend returned code ${error.status}, ` +
//        `error message was: ${error.error.error.message}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

}
